import { WeekDays } from '@helpers/weekDays';
import { Col, Row, Skeleton, Typography } from 'antd';
import { capitalize } from 'lodash';
import { FC } from 'react';
import { v4 } from 'uuid';
const { Text } = Typography;

import styles from '../Footer.module.scss';

type Props = {
  footerWorkingHoursData: ComponentUiWorkingHours | null | undefined;
  workingHoursLoading?: boolean;
};

const array = Array.from({ length: 7 });

const FooterWorkingHours: FC<Props> = ({
  footerWorkingHoursData,
  workingHoursLoading,
}) => {
  return (
    <>
      {workingHoursLoading ? (
        <Row gutter={[0, 16]}>
          {array.map((_, index) => (
            <Col key={index} span={24}>
              <Skeleton.Button active={true} block={true} size={'small'} />
            </Col>
          ))}
        </Row>
      ) : (
        footerWorkingHoursData && (
          <Row gutter={[0, 16]}>
            {Object.values(WeekDays).map((day) => (
              <Col span={24} key={v4()}>
                <Text className={styles.footerText}>
                  {capitalize(day)}:{' '}
                  {footerWorkingHoursData?.[day]
                    ? footerWorkingHoursData?.[day]
                    : 'Closed'}
                </Text>
              </Col>
            ))}
          </Row>
        )
      )}
    </>
  );
};

export default FooterWorkingHours;
