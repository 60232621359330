import { useNavigate } from 'react-router';

import { useLoginMutation, useWebsiteQuery } from '@/graphql';

import { customerWebsiteRoutes } from '@router/routes';

import { useStatusMessage } from '@app/StatusMessageContext/statusMessageContext';
import { setAuthToken } from '@helpers/auth';
import { getWebsiteFilter } from '@helpers/getWebsiteFilters';

export const useLogin = (): ReturnType<typeof useLoginMutation> => {
  const navigate = useNavigate();
  const message = useStatusMessage();
  const { data } = useWebsiteQuery(getWebsiteFilter());
  const website = data?.websites?.data?.[0];
  return useLoginMutation({
    onCompleted: (data) => {
      if (data?.login?.jwt) {
        setAuthToken(data?.login?.jwt);
        navigate(customerWebsiteRoutes.home);
        message.open(
          'success',
          `Welcome to ${website?.attributes?.initialSettings?.heading?.title}`,
        );
      }
    },
    onError: (error, clientOptions) => {
      if (error.message.includes('Your account email is not confirmed')) {
        navigate(customerWebsiteRoutes.auth.notConfirmed, {
          state: {
            email: clientOptions?.variables?.input?.identifier,
          },
        });
      }
      if (error.message === 'Invalid identifier or password') {
        message.open('error', 'Invalid email or password');
      }
    },
  });
};
