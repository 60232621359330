import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import DefaultLayout, { Loader } from '@components/layout/MainLayout';

import { websiteRoutes } from '@pages/website/routes';

import authRoutes from '@pages/auth/routes';
import { customerWebsiteRoutes } from '@router/routes';

const ErrorPage = lazy<FC>(() => import('@/pages/errorPage'));

const { index } = customerWebsiteRoutes;

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        element: <Navigate to={index} />,
        index: true,
      },
      websiteRoutes,
      {
        Component: ErrorPage,
        path: '*',
      },
    ],
  },
  authRoutes,
];

export default routes;
