import { FC, SVGProps } from 'react';

import { ReactComponent as ArrowDown } from './icons/arrow-down.svg';
import { ReactComponent as ArrowSliderLeft } from './icons/arrow-slider-left.svg';
import { ReactComponent as ArrowSliderRight } from './icons/arrow-slider-right.svg';
import { ReactComponent as ArrowUpCollapseIcon } from './icons/arrow-up-collapse.svg';
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg';
import { ReactComponent as Bin } from './icons/bin.svg';
import { ReactComponent as BurgerMenu } from './icons/burger-menu.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as CartIcon } from './icons/cart.svg';
import { ReactComponent as ChatIcon } from './icons/chat.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from './icons/chevron-up.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as CoLogo } from './icons/co-logo.svg';
import { ReactComponent as CopyIcon } from './icons/copy.svg';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import { ReactComponent as EyeIcon } from './icons/eye.svg';
import { ReactComponent as FacebookIconCard } from './icons/facebook-icon.svg';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { ReactComponent as FacebookIcon } from './icons/footer-facebook.svg';
import { ReactComponent as InstagramIcon } from './icons/footer-insta.svg';
import { ReactComponent as LinkedIcon } from './icons/footer-linked.svg';
import { ReactComponent as PinterestIcon } from './icons/footer-pint.svg';
import { ReactComponent as TwitterIcon } from './icons/footer-twitter.svg';
import { ReactComponent as XIcon } from './icons/footer-x.svg';
import { ReactComponent as ImagePlaceholder } from './icons/image-placeholder.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as InstagramIconCard } from './icons/insta-icon.svg';
import { ReactComponent as LinkedInIconCard } from './icons/linked-in-icon.svg';
import { ReactComponent as LockIcon } from './icons/lock.svg';
import { ReactComponent as MagGlassDisabled } from './icons/mag-glass-disabled.svg';
import { ReactComponent as MagGlass } from './icons/mag-glass.svg';
import { ReactComponent as MinusIcon } from './icons/minus.svg';
import { ReactComponent as Pencil } from './icons/pencil.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as Quotes } from './icons/quotes.svg';
import { ReactComponent as RedoIcon } from './icons/redo.svg';
import { ReactComponent as ShieldIcon } from './icons/shield.svg';
import { ReactComponent as SignOutIcon } from './icons/sign-out.svg';
import { ReactComponent as SortIcon } from './icons/sort.svg';
import { ReactComponent as TruckIcon } from './icons/truck.svg';
import { ReactComponent as UserSecondaryIcon } from './icons/user-secondary.svg';
import { ReactComponent as UserIcon } from './icons/user.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';

export type IconType =
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-slider-right'
  | 'arrow-slider-left'
  | 'burger-menu'
  | 'close'
  | 'cart'
  | 'co-logo'
  | 'mag-glass'
  | 'mag-glass-disabled'
  | 'quotes'
  | 'image-placeholder'
  | 'instagram-icon-card'
  | 'facebook-icon-card'
  | 'linkedin-icon-card'
  | 'instagram'
  | 'facebook'
  | 'pinterest'
  | 'twitter'
  | 'linkedin'
  | 'user'
  | 'plus'
  | 'minus'
  | 'sort'
  | 'redo'
  | 'info'
  | 'calendar'
  | 'eye'
  | 'truck'
  | 'shield'
  | 'lock'
  | 'filter'
  | 'pencil'
  | 'bin'
  | 'arrow-up-collapse'
  | 'copy'
  | 'chevron-up'
  | 'chevron-down'
  | 'user-secondary'
  | 'chat'
  | 'sign-out'
  | 'x'
  | 'download'
  | 'warning';

const iconsMap: Record<IconType, FC<SVGProps<SVGSVGElement>>> = {
  ['arrow-up']: ArrowUp,
  ['arrow-down']: ArrowDown,
  ['burger-menu']: BurgerMenu,
  ['close']: CloseIcon,
  ['cart']: CartIcon,
  ['co-logo']: CoLogo,
  ['mag-glass']: MagGlass,
  ['mag-glass-disabled']: MagGlassDisabled,
  ['user']: UserIcon,
  ['arrow-slider-right']: ArrowSliderRight,
  ['arrow-slider-left']: ArrowSliderLeft,
  ['quotes']: Quotes,
  ['image-placeholder']: ImagePlaceholder,
  ['instagram-icon-card']: InstagramIconCard,
  ['facebook-icon-card']: FacebookIconCard,
  ['linkedin-icon-card']: LinkedInIconCard,
  ['instagram']: InstagramIcon,
  ['facebook']: FacebookIcon,
  ['pinterest']: PinterestIcon,
  ['twitter']: TwitterIcon,
  ['linkedin']: LinkedIcon,
  ['plus']: PlusIcon,
  ['minus']: MinusIcon,
  ['sort']: SortIcon,
  ['redo']: RedoIcon,
  ['info']: InfoIcon,
  ['calendar']: CalendarIcon,
  ['eye']: EyeIcon,
  ['truck']: TruckIcon,
  ['shield']: ShieldIcon,
  ['lock']: LockIcon,
  ['filter']: FilterIcon,
  ['arrow-up-collapse']: ArrowUpCollapseIcon,
  ['pencil']: Pencil,
  ['bin']: Bin,
  ['copy']: CopyIcon,
  ['chevron-up']: ChevronUpIcon,
  ['chevron-down']: ChevronDownIcon,
  ['user-secondary']: UserSecondaryIcon,
  ['chat']: ChatIcon,
  ['sign-out']: SignOutIcon,
  ['x']: XIcon,
  ['download']: DownloadIcon,
  ['warning']: WarningIcon,
};

export enum IconSize {
  XXS = 12,
  Tiny = 14,
  Small = 16,
  Medium = 20,
  Large = 24,
}

type SvgIconProps = SVGProps<SVGSVGElement> & {
  type?: IconType;
  size?: IconSize;
  exactSize?: number;
};

export const Icon: FC<SvgIconProps> = ({
  type,
  size = IconSize.Medium,
  exactSize,
}) => {
  if (!type) {
    return null;
  }

  const Icon = iconsMap[type];

  const resultSize = exactSize || size;

  return <Icon width={resultSize} height={resultSize} />;
};
