import { FC, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import LoggedInRoutes from '@auth/LoggedInRoutes';
import CheckoutSuccessPage from '@pages/website/checkout/CheckoutSuccessPage';
import { customerWebsiteRoutes } from '@router/routes';

const WebsiteWrapper = lazy<FC>(() => import('@/pages/website'));

const Home = lazy<FC>(() => import('@pages/website/home/Home'));
const Shop = lazy<FC>(() => import('@pages/website/shop/Shop'));
const AboutUs = lazy<FC>(() => import('@pages/website/about-us/AboutUs'));
const Faq = lazy<FC>(() => import('@pages/website/faq/Faq'));
const Blog = lazy<FC>(() => import('@pages/website/blog/Blog'));
const BlogArticle = lazy<FC>(
  () => import('@pages/website/blog-article/BlogArticle'),
);
const ShopProduct = lazy<FC>(
  () => import('@pages/website/shop-product/ShopProduct'),
);
const PrivacyPolicy = lazy<FC>(
  () => import('@pages/website/privacy-policy/PrivacyPolicy'),
);
const TermsAndConditions = lazy<FC>(
  () => import('@pages/website/terms-and-conditions/TermsAndConditions'),
);
const Checkout = lazy<FC>(() => import('@pages/website/checkout/Checkout'));
const Account = lazy<FC>(() => import('@pages/website/account/Account'));

const {
  home,
  shop,
  aboutUs,
  faq,
  blog,
  checkout,
  account,
  privacyPolicy,
  termsAndConditions,
  checkoutSuccess,
} = customerWebsiteRoutes;

export const websiteRoutes: RouteObject = {
  path: '',
  Component: WebsiteWrapper,
  children: [
    {
      index: true,
      element: <Navigate to={home} />,
    },
    {
      path: home,
      Component: Home,
    },
    {
      path: `${shop}/:location/:type`,
      Component: Shop,
    },
    {
      path: `${shop}/:location/:type/:productId`,
      Component: ShopProduct,
    },
    {
      path: aboutUs,
      Component: AboutUs,
    },
    {
      path: faq,
      Component: Faq,
    },
    {
      path: blog,
      Component: Blog,
    },
    {
      path: `${blog}/:pageId`,
      Component: BlogArticle,
    },
    {
      path: privacyPolicy,
      Component: PrivacyPolicy,
    },
    {
      path: termsAndConditions,
      Component: TermsAndConditions,
    },
    {
      Component: LoggedInRoutes,
      children: [
        {
          path: checkout,
          Component: Checkout,
        },
        {
          path: checkoutSuccess,
          Component: CheckoutSuccessPage,
        },
        {
          path: `${account}/:currentKey`,
          Component: Account,
        },
      ],
    },
  ],
};
