import { useBreakpoint } from '@hooks/useBreakpoint';
import { customerWebsiteRoutes } from '@router/routes';
import { Logo } from '@ui/logo';
import { Col, Row, Skeleton, Typography } from 'antd';
import { FC } from 'react';
const { Text } = Typography;

import styles from '../Footer.module.scss';
const { home } = customerWebsiteRoutes;

type Props = {
  footerLogo: Maybe<string>;
  footerText: Maybe<string>;
  footerLoading?: boolean;
};

const FooterMainText: FC<Props> = ({
  footerLogo,
  footerText,
  footerLoading,
}) => {
  const { xl } = useBreakpoint();

  return (
    <Col span={24} xl={10} className={styles.mainTextContent}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Logo
            link={home}
            url={footerLogo || ''}
            logoLoading={footerLoading}
          />
        </Col>
        <Col span={24} md={18} xl={24}>
          {footerLoading ? (
            <Skeleton
              active={true}
              title={false}
              paragraph={{ rows: xl ? 3 : 2 }}
            />
          ) : (
            <Text className={styles.mainText}>{footerText}</Text>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default FooterMainText;
