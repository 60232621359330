import { FC, ReactNode, useMemo, useState } from 'react';

import { NavLink, useSearchParams } from 'react-router-dom';

import type { MenuProps } from 'antd';
import { Dropdown, Menu } from 'antd';

import classNames from 'classnames';

import { customerWebsiteRoutes } from '@router/routes';

import { useBreakpoint } from '@hooks/useBreakpoint';
import { useToken } from '@hooks/useToken';

import { Icon, IconType } from '@assets/icon';

import { useProductTypesQuery } from '@/graphql';
import { useStorageLocation, useStorageType } from '@hooks/useStorage';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import styles from './HeaderMenu.module.scss';

const { home, shop, aboutUs, blog, faq } = customerWebsiteRoutes;

type SidebarMenuItem = {
  key: string;
  label: string | ReactNode;
  iconType?: IconType;
};

type Props = {
  handleToggleMobileMenu?: MenuClickEventHandler | undefined;
  menuMode: 'vertical' | 'horizontal' | 'inline';
};

const HeaderMenu: FC<Props> = ({ handleToggleMobileMenu, menuMode }) => {
  const { token } = useToken();
  const { lg } = useBreakpoint();
  const selectedKey = location.pathname.split('/')[3];
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const locationId = useStorageLocation();
  const type = useStorageType();

  const { data, loading } = useProductTypesQuery({
    variables: {
      sort: ['name:ASC'],
      pagination: { limit: -1 },
    },
  });

  const items: MenuProps['items'] = useMemo(() => {
    const category = searchParams.get('category');
    const isShopPage = location.pathname === shop;

    const getItemLabel = (name: string | undefined, fontColor: string) => (
      <NavLink
        to={
          name === 'All'
            ? `${shop}/${locationId}/products`
            : `${shop}/${locationId}/products?category=${name}`
        }
        style={{ color: fontColor }}
      >
        {name}
      </NavLink>
    );

    return [
      {
        key: '1',
        label: getItemLabel(
          'All',
          isShopPage && category === null ? '#1f2933' : '',
        ),
      },
      ...(data?.productTypes?.data?.map((item, index) => ({
        key: String(index + 2),
        label: getItemLabel(
          item.attributes?.name,
          category === item.attributes?.name ? '#1f2933' : '',
        ),
      })) ?? []),
    ];
  }, [data, searchParams, locationId]);

  const itemsArray: SidebarMenuItem[] = useMemo(
    () => [
      {
        key: home,
        label: 'Home',
      },
      {
        key: lg ? shop : `${shop}/${locationId}/${type}`,
        label: lg ? (
          loading ? (
            <NavLink
              to={`${shop}/${locationId}/${type}`}
              className={styles.menuItemWithDropdown}
            >
              Shop
              <Icon type={dropdownVisible ? 'chevron-up' : 'chevron-down'} />
            </NavLink>
          ) : (
            <Dropdown
              menu={{ items }}
              onOpenChange={(visible) => setDropdownVisible(visible)}
              overlayClassName={styles.menuDropdownWrapper}
            >
              <NavLink
                to={`${shop}/${locationId}/${type}`}
                style={{ color: dropdownVisible ? '#1f2933' : '' }}
                className={styles.menuItemWithDropdown}
              >
                Shop
                <Icon type={dropdownVisible ? 'chevron-up' : 'chevron-down'} />
              </NavLink>
            </Dropdown>
          )
        ) : (
          'Shop'
        ),
      },
      {
        key: aboutUs,
        label: 'About Us',
      },
      {
        key: blog,
        label: 'Blog',
      },
      {
        key: faq,
        label: 'FAQ',
      },
    ],
    [lg, loading, dropdownVisible, items, locationId, type],
  );

  const menuItems = useMemo(() => {
    const getItems = (arr: SidebarMenuItem[], selectedKey: string) =>
      arr?.map((it) => ({
        key: it?.key,
        icon: it?.iconType ? <Icon type={it?.iconType} /> : null,
        label:
          it?.key === shop && lg ? (
            <>{it?.label}</>
          ) : (
            <NavLink to={it?.key}>{it?.label}</NavLink>
          ),
        className: classNames(styles.item, {
          [styles.active]: it?.key === selectedKey,
        }),
      }));
    return getItems(itemsArray, selectedKey);
  }, [itemsArray, selectedKey, lg]);

  return (
    <Menu
      theme={'light'}
      mode={menuMode}
      selectedKeys={selectedKey ? [selectedKey] : []}
      className={lg ? styles.menu : styles.menuMobile}
      style={{ color: token.colorTextSecondary }}
      items={menuItems}
      onClick={handleToggleMobileMenu}
    />
  );
};

export default HeaderMenu;
