import { ThemeConfig } from 'antd/es/config-provider';

import { colors } from '@/themes/_colors';
import { typography } from '@/themes/_typography';

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: 'unset',
      colorPrimaryBg: 'unset',
      itemActiveBg: colors.colorBgTextHover,
      itemColor: colors.colorText,
      fontSize: 14,
    },
    Layout: {
      colorBgLayout: 'unset',
      bodyBg: 'unset',
      headerBg: 'unset',
      siderBg: 'unset',
    },
    Button: {
      colorText: colors.colorPrimary,
      colorBorder: colors.colorBorderSecondary,
      borderRadiusLG: 0,
      borderRadiusSM: 0,
      fontSize: 10,
      fontSizeLG: 16,
      lineHeight: 1.3,
      controlHeight: 24,
      controlHeightLG: 40,
      controlHeightSM: 35,
      controlHeightXS: 30,
      marginLG: 6,
      borderColorDisabled: '#E7E7E7',
      primaryShadow: 'none',
      boxShadow: 'none',
      defaultBorderColor: colors.colorPrimary,
      fontWeight: 'normal',
      fontWeightStrong: 500,
      colorLink: 'black',
      colorLinkHover: 'gray',
      linkDecoration: 'underline',
    },
    Card: {
      colorBorderSecondary: colors.colorSplit,
      padding: 20,
      boxShadowTertiary: 'none',
    },
    Typography: {
      titleMarginTop: 0,
      titleMarginBottom: 0,
    },
    Segmented: {
      itemSelectedBg: '#E7ECF8',
      colorBgLayout: '#fff',
      fontSize: 12,
      itemColor: colors.colorTextDescription,
      itemHoverColor: colors.colorPrimary,
    },
    Select: {
      optionSelectedBg: 'transparent',
      optionSelectedFontWeight: 'initial',
      optionPadding: '8px 16px',
      optionHeight: 24,
      controlHeight: 40,
      borderRadius: 0,
      boxShadowTertiary: '0px 0px 3px #ababab',
      boxShadow: '0px 0px 3px #ababab',
      borderRadiusOuter: 0,
    },
    Form: {
      labelFontSize: 12,
      labelColor: colors.colorTextSecondary,
      verticalLabelPadding: 0,
      itemMarginBottom: 12,
    },
    Input: {
      controlHeight: 40,
      borderRadius: 0,
      activeShadow: '0px 0px 3px #ababab',
      colorBorder: '#d9d9d9',
      fontSize: 16,
    },
    DatePicker: {
      controlHeight: 40,
      borderRadius: 0,
      activeShadow: '0px 0px 3px #ababab',
    },
    Badge: {
      indicatorHeightSM: 16,
    },
    Notification: {
      colorText: colors.colorText,
      colorTextHeading: colors.colorTextSecondary,
      colorBgElevated: '#f2f2f2',
      borderRadius: 0,
      borderRadiusOuter: 0,
      borderRadiusLG: 0,
      borderRadiusSM: 0,
      borderRadiusXS: 0,
    },
  },
  token: {
    ...colors,
    ...typography,
  },
};

export default theme;
