import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "FindSlugResponse": [
      "TenantEntityResponse"
    ],
    "GenericMorph": [
      "Activity",
      "Appraisal",
      "ApprovalMethod",
      "Article",
      "AuthLayout",
      "Backing",
      "BoxPaper",
      "BusinessLocation",
      "Campaign",
      "CampaignEnrolledContact",
      "CampaignEnrolledLead",
      "Carrier",
      "ChartAccount",
      "ChartCategory",
      "ChartSubcategory",
      "ChatNotification",
      "Class",
      "ClassLocationInfo",
      "ClassOrderItem",
      "ClassPerformer",
      "Company",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiAuthContent",
      "ComponentUiCard",
      "ComponentUiCustomerReviewSection",
      "ComponentUiExtendedSection",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiProductTypesVisibility",
      "ComponentUiReviewCard",
      "ComponentUiSection",
      "ComponentUiSectionsVisibility",
      "ComponentUiShipmentCost",
      "ComponentUiTab",
      "ComponentUiText",
      "ComponentUiWebsiteContacts",
      "ComponentUiWorkingHours",
      "CompositeProduct",
      "CompositeProductLocationInfo",
      "CompositeProductOrderItem",
      "ConditionType",
      "Contact",
      "Contract",
      "ContractTemplate",
      "Conversation",
      "Country",
      "CustomPermission",
      "Deal",
      "DealTransaction",
      "DealTransactionReminder",
      "DesignStyle",
      "Dimension",
      "Discount",
      "DiscountUsageEvent",
      "DownloadRecord",
      "EmailDesignerEmailTemplate",
      "EngravingType",
      "EnrolledContactCondition",
      "EnrolledLeadCondition",
      "FileItem",
      "Form",
      "FormTemplate",
      "GenderType",
      "Home",
      "InventoryAdjustment",
      "InventoryAdjustmentItem",
      "InventoryQuantityNotification",
      "Invoice",
      "InvoiceShippingContact",
      "JewelryProduct",
      "JewelryProductType",
      "KnotStyle",
      "Layout",
      "Lead",
      "LinkStyle",
      "LinkType",
      "Location",
      "MailTemplate",
      "Maintenance",
      "MaintenanceEvent",
      "MaintenanceQuantityNotification",
      "ManufacturingProcess",
      "MarketingCustomersReport",
      "MarketingEmailTemplate",
      "MaterialGrade",
      "Membership",
      "MembershipItem",
      "MembershipOrderItem",
      "MetalFinishType",
      "MetalType",
      "Note",
      "NotificationMethod",
      "NotificationsNylasGrantExpire",
      "NylasConnection",
      "Onboarding",
      "OnboardingUser",
      "Order",
      "OrderStatusNotification",
      "PayRate",
      "Piece",
      "Platform",
      "PlattingType",
      "Product",
      "ProductAttribute",
      "ProductAttributeOption",
      "ProductBrand",
      "ProductGroup",
      "ProductGroupAttribute",
      "ProductGroupAttributeOption",
      "ProductGroupItem",
      "ProductInventoryItem",
      "ProductInventoryItemEvent",
      "ProductOrderItem",
      "ProductType",
      "PublicContract",
      "PublicForm",
      "PurchaseRequest",
      "PurchaseRequestShippingInfo",
      "QuantityDifferenceType",
      "Question",
      "Rate",
      "ReactIconsIconlibrary",
      "RentableData",
      "ReportsSchedule",
      "Resource",
      "ResourceCount",
      "ResourceInventoryItem",
      "Return",
      "ReturnItem",
      "ReturnMethod",
      "SalesItemReport",
      "SequenceStep",
      "SequenceStepInfo",
      "Service",
      "ServiceLocationInfo",
      "ServiceOrderItem",
      "ServicePerformer",
      "ShankStyle",
      "Shipment",
      "ShipmentCard",
      "ShipmentCarrier",
      "ShipmentPackage",
      "ShippingMethod",
      "Size",
      "SlugifySlug",
      "SpecificType",
      "StagingLog",
      "Stone",
      "StoneClarity",
      "StoneClarityEnhancement",
      "StoneClarityType",
      "StoneColor",
      "StoneColorDominant",
      "StoneColorEnhancement",
      "StoneColorIntensity",
      "StoneColorSecondary",
      "StoneCut",
      "StoneCutStyle",
      "StoneFluorescence",
      "StoneGeographic",
      "StoneGirdleFinish",
      "StoneGirdleThickness",
      "StoneGradingReport",
      "StoneHue",
      "StoneIntensity",
      "StoneOrigin",
      "StonePolish",
      "StoneSettingFamily",
      "StoneSettingName",
      "StoneShape",
      "StoneSymmetry",
      "StoneTone",
      "StoneTransparency",
      "StoneTreatment",
      "StoneType",
      "Strand",
      "StrandsLength",
      "StripeOnboarding",
      "Sublocation",
      "SublocationItem",
      "Task",
      "TaskLocation",
      "TaskType",
      "Tax",
      "TaxAuthority",
      "TaxReport",
      "Tenant",
      "TimePeriod",
      "Todo",
      "Transaction",
      "TransferOrder",
      "TransferOrderItem",
      "TwilioConnection",
      "UploadFile",
      "UploadFolder",
      "UserNotification",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website",
      "Weight"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FindSlugResponse": [
      "TenantEntityResponse"
    ],
    "GenericMorph": [
      "Activity",
      "Appraisal",
      "ApprovalMethod",
      "Article",
      "AuthLayout",
      "Backing",
      "BoxPaper",
      "BusinessLocation",
      "Campaign",
      "CampaignEnrolledContact",
      "CampaignEnrolledLead",
      "Carrier",
      "ChartAccount",
      "ChartCategory",
      "ChartSubcategory",
      "ChatNotification",
      "Class",
      "ClassLocationInfo",
      "ClassOrderItem",
      "ClassPerformer",
      "Company",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiAuthContent",
      "ComponentUiCard",
      "ComponentUiCustomerReviewSection",
      "ComponentUiExtendedSection",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiProductTypesVisibility",
      "ComponentUiReviewCard",
      "ComponentUiSection",
      "ComponentUiSectionsVisibility",
      "ComponentUiShipmentCost",
      "ComponentUiTab",
      "ComponentUiText",
      "ComponentUiWebsiteContacts",
      "ComponentUiWorkingHours",
      "CompositeProduct",
      "CompositeProductLocationInfo",
      "CompositeProductOrderItem",
      "ConditionType",
      "Contact",
      "Contract",
      "ContractTemplate",
      "Conversation",
      "Country",
      "CustomPermission",
      "Deal",
      "DealTransaction",
      "DealTransactionReminder",
      "DesignStyle",
      "Dimension",
      "Discount",
      "DiscountUsageEvent",
      "DownloadRecord",
      "EmailDesignerEmailTemplate",
      "EngravingType",
      "EnrolledContactCondition",
      "EnrolledLeadCondition",
      "FileItem",
      "Form",
      "FormTemplate",
      "GenderType",
      "Home",
      "InventoryAdjustment",
      "InventoryAdjustmentItem",
      "InventoryQuantityNotification",
      "Invoice",
      "InvoiceShippingContact",
      "JewelryProduct",
      "JewelryProductType",
      "KnotStyle",
      "Layout",
      "Lead",
      "LinkStyle",
      "LinkType",
      "Location",
      "MailTemplate",
      "Maintenance",
      "MaintenanceEvent",
      "MaintenanceQuantityNotification",
      "ManufacturingProcess",
      "MarketingCustomersReport",
      "MarketingEmailTemplate",
      "MaterialGrade",
      "Membership",
      "MembershipItem",
      "MembershipOrderItem",
      "MetalFinishType",
      "MetalType",
      "Note",
      "NotificationMethod",
      "NotificationsNylasGrantExpire",
      "NylasConnection",
      "Onboarding",
      "OnboardingUser",
      "Order",
      "OrderStatusNotification",
      "PayRate",
      "Piece",
      "Platform",
      "PlattingType",
      "Product",
      "ProductAttribute",
      "ProductAttributeOption",
      "ProductBrand",
      "ProductGroup",
      "ProductGroupAttribute",
      "ProductGroupAttributeOption",
      "ProductGroupItem",
      "ProductInventoryItem",
      "ProductInventoryItemEvent",
      "ProductOrderItem",
      "ProductType",
      "PublicContract",
      "PublicForm",
      "PurchaseRequest",
      "PurchaseRequestShippingInfo",
      "QuantityDifferenceType",
      "Question",
      "Rate",
      "ReactIconsIconlibrary",
      "RentableData",
      "ReportsSchedule",
      "Resource",
      "ResourceCount",
      "ResourceInventoryItem",
      "Return",
      "ReturnItem",
      "ReturnMethod",
      "SalesItemReport",
      "SequenceStep",
      "SequenceStepInfo",
      "Service",
      "ServiceLocationInfo",
      "ServiceOrderItem",
      "ServicePerformer",
      "ShankStyle",
      "Shipment",
      "ShipmentCard",
      "ShipmentCarrier",
      "ShipmentPackage",
      "ShippingMethod",
      "Size",
      "SlugifySlug",
      "SpecificType",
      "StagingLog",
      "Stone",
      "StoneClarity",
      "StoneClarityEnhancement",
      "StoneClarityType",
      "StoneColor",
      "StoneColorDominant",
      "StoneColorEnhancement",
      "StoneColorIntensity",
      "StoneColorSecondary",
      "StoneCut",
      "StoneCutStyle",
      "StoneFluorescence",
      "StoneGeographic",
      "StoneGirdleFinish",
      "StoneGirdleThickness",
      "StoneGradingReport",
      "StoneHue",
      "StoneIntensity",
      "StoneOrigin",
      "StonePolish",
      "StoneSettingFamily",
      "StoneSettingName",
      "StoneShape",
      "StoneSymmetry",
      "StoneTone",
      "StoneTransparency",
      "StoneTreatment",
      "StoneType",
      "Strand",
      "StrandsLength",
      "StripeOnboarding",
      "Sublocation",
      "SublocationItem",
      "Task",
      "TaskLocation",
      "TaskType",
      "Tax",
      "TaxAuthority",
      "TaxReport",
      "Tenant",
      "TimePeriod",
      "Todo",
      "Transaction",
      "TransferOrder",
      "TransferOrderItem",
      "TwilioConnection",
      "UploadFile",
      "UploadFolder",
      "UserNotification",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website",
      "Weight"
    ]
  }
};
      export default result;
    
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
    size
    name
  }
}
    `;
export const ArticleMinFragmentDoc = gql`
    fragment ArticleMin on ArticleEntity {
  id
  attributes {
    title
    body
    active
    sentToSubscribers
    image {
      data {
        ...File
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export const ArticleFragmentDoc = gql`
    fragment Article on ArticleEntity {
  ...ArticleMin
  id
  attributes {
    owner {
      data {
        id
      }
    }
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const AuthContentFragmentDoc = gql`
    fragment AuthContent on ComponentUiAuthContent {
  id
  background {
    data {
      ...File
    }
  }
  headline {
    ...Headline
  }
  advantages {
    ...Headline
  }
}
    `;
export const SubLocationMinFragmentDoc = gql`
    fragment SubLocationMin on SublocationEntity {
  id
  attributes {
    name
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on LocationEntity {
  id
  attributes {
    address
    zipcode
  }
}
    `;
export const TaxMinFragmentDoc = gql`
    fragment TaxMin on TaxEntity {
  id
  attributes {
    name
    rate
    createdAt
    fixedFee
    perUnitFee
  }
}
    `;
export const BusinessLocationFragmentDoc = gql`
    fragment BusinessLocation on BusinessLocationEntity {
  id
  attributes {
    type
    name
    email
    phoneNumber
    location {
      data {
        ...Location
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    businessLocationId
    sublocations {
      data {
        ...SubLocationMin
      }
    }
    createdAt
    uuid
    archived
  }
}
    `;
export const SubLocationFragmentDoc = gql`
    fragment SubLocation on SublocationEntity {
  id
  ...SubLocationMin
  attributes {
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const CompositeProductLocationInfoMinFragmentDoc = gql`
    fragment CompositeProductLocationInfoMin on CompositeProductLocationInfoEntity {
  id
  attributes {
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    price
    favorite
    active
  }
}
    `;
export const CompositeProductMinFragmentDoc = gql`
    fragment CompositeProductMin on CompositeProductEntity {
  id
  attributes {
    name
    code
    description
    defaultPrice
    active
    notes
    uuid
    favorite
    createdAt
  }
}
    `;
export const DimensionFragmentDoc = gql`
    fragment Dimension on DimensionEntity {
  id
  attributes {
    height
    length
    width
    unit
  }
}
    `;
export const WeightFragmentDoc = gql`
    fragment Weight on WeightEntity {
  id
  attributes {
    value
    unit
  }
}
    `;
export const ProductBrandFragmentDoc = gql`
    fragment ProductBrand on ProductBrandEntity {
  id
  attributes {
    name
  }
}
    `;
export const ProductTypeFragmentDoc = gql`
    fragment ProductType on ProductTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const RentableDataFragmentDoc = gql`
    fragment RentableData on RentableDataEntity {
  id
  attributes {
    pricePerPeriod
    lostFee
    minimumRentalPeriod
    period
    enabled
  }
}
    `;
export const ProductMinFragmentDoc = gql`
    fragment ProductMin on ProductEntity {
  id
  attributes {
    defaultPrice
    productId
    name
    barcode
    uuid
    favorite
    model
    serialNumber
    SKU
    UPC
    MPN
    EAN
    ISBN
    partsWarranty
    laborWarranty
    description
    businessUseOnly
    bundleUseOnly
    expiryDate
    returnable
    packagingProduct
    active
    files {
      data {
        ...File
      }
    }
    dimension {
      data {
        ...Dimension
      }
    }
    weight {
      data {
        ...Weight
      }
    }
    brand {
      data {
        ...ProductBrand
      }
    }
    productType {
      data {
        ...ProductType
      }
    }
    rentableData {
      data {
        ...RentableData
      }
    }
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductEntity {
  ...ProductMin
  id
}
    `;
export const CompositeProductFragmentDoc = gql`
    fragment CompositeProduct on CompositeProductEntity {
  ...CompositeProductMin
  id
  attributes {
    products {
      data {
        ...Product
      }
    }
    compositeProductLocationInfos {
      data {
        ...CompositeProductLocationInfoMin
      }
    }
  }
}
    `;
export const CompositeProductLocationInfoFragmentDoc = gql`
    fragment CompositeProductLocationInfo on CompositeProductLocationInfoEntity {
  ...CompositeProductLocationInfoMin
  id
  attributes {
    compositeProduct {
      data {
        ...CompositeProduct
      }
    }
  }
}
    `;
export const CompositeProductLocationInfoWithQuantityFragmentDoc = gql`
    fragment CompositeProductLocationInfoWithQuantity on CompositeProductLocationInfoEntity {
  ...CompositeProductLocationInfo
  id
  attributes {
    quantity(businessLocationId: $businessLocationId)
  }
}
    `;
export const DiscountMinFragmentDoc = gql`
    fragment DiscountMin on DiscountEntity {
  id
  attributes {
    name
    notes
    description
    code
    usageLimit
    usagesLeft
    startDate
    endDate
    active
    amount
    type
    uuid
  }
}
    `;
export const TaxFragmentDoc = gql`
    fragment Tax on TaxEntity {
  ...TaxMin
}
    `;
export const ServiceMinFragmentDoc = gql`
    fragment ServiceMin on ServiceEntity {
  id
  attributes {
    name
    description
    defaultPrice
    uuid
    favorite
    createdAt
    active
    serviceId
    files {
      data {
        ...File
      }
    }
    houseCall
    tax {
      data {
        ...Tax
      }
    }
  }
}
    `;
export const ServiceLocationInfoMinFragmentDoc = gql`
    fragment ServiceLocationInfoMin on ServiceLocationInfoEntity {
  id
  attributes {
    favorite
    service {
      data {
        ...ServiceMin
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
  }
}
    `;
export const UserMinFragmentDoc = gql`
    fragment UserMin on UsersPermissionsUserEntity {
  id
  attributes {
    fullName
    email
    avatar {
      data {
        ...File
      }
    }
  }
}
    `;
export const ServicePerformerFragmentDoc = gql`
    fragment ServicePerformer on ServicePerformerEntity {
  id
  attributes {
    price
    uuid
    performer {
      data {
        ...UserMin
      }
    }
    serviceLocationInfo {
      data {
        ...ServiceLocationInfoMin
      }
    }
    duration
    pointsGiven
    active
  }
}
    `;
export const ServiceLocationInfoFragmentDoc = gql`
    fragment ServiceLocationInfo on ServiceLocationInfoEntity {
  ...ServiceLocationInfoMin
  id
  attributes {
    servicePerformers {
      data {
        ...ServicePerformer
      }
    }
    favorite
    uuid
  }
}
    `;
export const ServiceFragmentDoc = gql`
    fragment Service on ServiceEntity {
  ...ServiceMin
  id
  attributes {
    serviceLocationInfos {
      data {
        ...ServiceLocationInfo
      }
    }
  }
}
    `;
export const ClassMinFragmentDoc = gql`
    fragment ClassMin on ClassEntity {
  id
  attributes {
    name
    description
    uuid
    favorite
    defaultPrice
    createdAt
    showOnline
    workshop
    active
    classId
    tax {
      data {
        ...Tax
      }
    }
    files {
      data {
        ...File
      }
    }
  }
}
    `;
export const ClassLocationInfoMinFragmentDoc = gql`
    fragment ClassLocationInfoMin on ClassLocationInfoEntity {
  id
  attributes {
    favorite
    class {
      data {
        ...ClassMin
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
  }
}
    `;
export const ClassPerformerFragmentDoc = gql`
    fragment ClassPerformer on ClassPerformerEntity {
  id
  attributes {
    duration
    pointsGiven
    pointsRedeem
    price
    numberOfSessions
    liveStreamPrice
    uuid
    active
    performer {
      data {
        ...UserMin
      }
    }
    classLocationInfo {
      data {
        ...ClassLocationInfoMin
      }
    }
    createdAt
  }
}
    `;
export const ClassLocationInfoFragmentDoc = gql`
    fragment ClassLocationInfo on ClassLocationInfoEntity {
  ...ClassLocationInfoMin
  id
  attributes {
    classPerformers {
      data {
        ...ClassPerformer
      }
    }
    favorite
    uuid
  }
}
    `;
export const ClassFragmentDoc = gql`
    fragment Class on ClassEntity {
  ...ClassMin
  id
  attributes {
    classLocationInfos {
      data {
        ...ClassLocationInfo
      }
    }
  }
}
    `;
export const MembershipMinFragmentDoc = gql`
    fragment MembershipMin on MembershipEntity {
  id
  attributes {
    name
    description
    price
    rewardPoints
    durationCount
    durationPeriod
    active
    membershipId
    tax {
      data {
        ...Tax
      }
    }
    favorite
    uuid
  }
}
    `;
export const MembershipItemFragmentDoc = gql`
    fragment MembershipItem on MembershipItemEntity {
  id
  attributes {
    itemsQuantity
    membership {
      data {
        ...MembershipMin
      }
    }
    compositeProduct {
      data {
        ...CompositeProduct
      }
    }
    product {
      data {
        ...Product
      }
    }
    service {
      data {
        ...Service
      }
    }
    class {
      data {
        ...Class
      }
    }
  }
}
    `;
export const MembershipFragmentDoc = gql`
    fragment Membership on MembershipEntity {
  ...MembershipMin
  id
  attributes {
    membershipItems {
      data {
        ...MembershipItem
      }
    }
  }
}
    `;
export const DiscountFragmentDoc = gql`
    fragment Discount on DiscountEntity {
  ...DiscountMin
  id
  attributes {
    applicableProducts {
      data {
        ...Product
      }
    }
    applicableServices {
      data {
        ...Service
      }
    }
    applicableClasses {
      data {
        ...Class
      }
    }
    applicableMemberships {
      data {
        ...Membership
      }
    }
    applicableCompositeProducts {
      data {
        ...CompositeProduct
      }
    }
    applicableStores {
      data {
        ...BusinessLocation
      }
    }
    excludedProducts {
      data {
        ...Product
      }
    }
    excludedServices {
      data {
        ...Service
      }
    }
    excludedClasses {
      data {
        ...Class
      }
    }
    excludedMemberships {
      data {
        ...Membership
      }
    }
    excludedCompositeProducts {
      data {
        ...CompositeProduct
      }
    }
  }
}
    `;
export const BackingFragmentDoc = gql`
    fragment Backing on BackingEntity {
  id
  attributes {
    name
  }
}
    `;
export const BoxPaperFragmentDoc = gql`
    fragment BoxPaper on BoxPaperEntity {
  id
  attributes {
    name
  }
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on CountryEntity {
  id
  attributes {
    name
  }
}
    `;
export const KnotStyleFragmentDoc = gql`
    fragment KnotStyle on KnotStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const LinkStyleFragmentDoc = gql`
    fragment LinkStyle on LinkStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const LinkTypeFragmentDoc = gql`
    fragment LinkType on LinkTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const StrandFragmentDoc = gql`
    fragment Strand on StrandEntity {
  id
  attributes {
    name
  }
}
    `;
export const StrandsLengthFragmentDoc = gql`
    fragment StrandsLength on StrandsLengthEntity {
  id
  attributes {
    name
  }
}
    `;
export const OrderMinFragmentDoc = gql`
    fragment OrderMin on OrderEntity {
  id
  attributes {
    orderId
    createdAt
    total
    subTotal
    dueDate
    completed
    status
    paymentMethod
    deliveryMethod
    discount
    shipment
    tax
    tip
    recurringAmount
    recurringPeriod
    recurringPeriodCount
    points
    itemsAmount
    paidSummary
    type
    createdAt
    rentDueDate
    isWarranty
    memo
    getCreateDate
  }
}
    `;
export const ProductInventoryItemFragmentDoc = gql`
    fragment ProductInventoryItem on ProductInventoryItemEntity {
  id
  attributes {
    price
    quantity
    lowQuantity
    maxQuantity
    minOrderQuantity
    pointsGiven
    pointsRedeemed
    storageNotes
    favorite
    uuid
    active
    sublocation {
      data {
        ...SubLocationMin
      }
    }
    product {
      data {
        ...ProductMin
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
  }
}
    `;
export const ProductOrderItemFragmentDoc = gql`
    fragment ProductOrderItem on ProductOrderItemEntity {
  id
  attributes {
    __typename
    quantity
    purchaseType
    status
    note
    itemId
    rentStart
    rentEnd
    price
    isShowInvoiceNote
    discounts {
      data {
        ...DiscountMin
      }
    }
    product {
      data {
        ...ProductInventoryItem
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    totalPricePerItem
    discountAmountPerItem
    sublocation {
      data {
        ...SubLocationMin
      }
    }
  }
}
    `;
export const CompositeProductOrderItemFragmentDoc = gql`
    fragment CompositeProductOrderItem on CompositeProductOrderItemEntity {
  id
  attributes {
    quantity
    purchaseType
    note
    itemId
    price
    status
    isShowInvoiceNote
    discounts {
      data {
        ...DiscountMin
      }
    }
    compositeProduct {
      data {
        ...CompositeProductLocationInfo
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const ServiceOrderItemFragmentDoc = gql`
    fragment ServiceOrderItem on ServiceOrderItemEntity {
  id
  attributes {
    quantity
    purchaseType
    status
    note
    itemId
    price
    dueDate
    isShowInvoiceNote
    discounts {
      data {
        ...DiscountMin
      }
    }
    service {
      data {
        ...ServicePerformer
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const ClassOrderItemFragmentDoc = gql`
    fragment ClassOrderItem on ClassOrderItemEntity {
  id
  attributes {
    quantity
    itemId
    note
    price
    status
    isShowInvoiceNote
    discounts {
      data {
        ...DiscountMin
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    class {
      data {
        ...ClassPerformer
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const MembershipOrderItemFragmentDoc = gql`
    fragment MembershipOrderItem on MembershipOrderItemEntity {
  id
  attributes {
    quantity
    itemId
    note
    status
    price
    isShowInvoiceNote
    discounts {
      data {
        ...DiscountMin
      }
    }
    membership {
      data {
        ...MembershipMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const OrderWithItemsFragmentDoc = gql`
    fragment OrderWithItems on OrderEntity {
  id
  attributes {
    orderId
    products {
      data {
        ...ProductOrderItem
      }
    }
    compositeProducts {
      data {
        ...CompositeProductOrderItem
      }
    }
    services {
      data {
        ...ServiceOrderItem
      }
    }
    classes {
      data {
        ...ClassOrderItem
      }
    }
    memberships {
      data {
        ...MembershipOrderItem
      }
    }
  }
}
    `;
export const ContactMinFragmentDoc = gql`
    fragment ContactMin on ContactEntity {
  id
  attributes {
    uuid
    email
    fullName
    phoneNumber
    leadSource
    getCreateDate
    avatar {
      data {
        ...File
      }
    }
    address
    points
    birthdayDate
    jobTitle
    anniversaryDate
  }
}
    `;
export const ContactDynamicValuesFragmentDoc = gql`
    fragment ContactDynamicValues on ContactEntity {
  id
  attributes {
    totalItemsPurchased
    totalSpent
    lastPurchaseDate
    annualRevenue
    numberOfTransactions
    biggestTransaction
    amountOwes
  }
}
    `;
export const LeadMinFragmentDoc = gql`
    fragment LeadMin on LeadEntity {
  id
  attributes {
    uuid
    email
    fullName
    phoneNumber
    address
    leadSource
    leadStage
    avatar {
      data {
        ...File
      }
    }
    createdAt
  }
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on UsersPermissionsRoleEntityResponse {
  data {
    id
    attributes {
      name
      description
      type
    }
  }
}
    `;
export const TenantFragmentDoc = gql`
    fragment Tenant on TenantEntity {
  id
  attributes {
    companyName
    slug
    email
    phoneNumber
    websiteUrl
    logo {
      data {
        ...File
      }
    }
    mainLocation {
      data {
        ...Location
      }
    }
  }
}
    `;
export const ShipmentCardMinFragmentDoc = gql`
    fragment ShipmentCardMin on ShipmentCardEntity {
  id
  attributes {
    firstName
    lastName
    email
    streetName
    apartment
    companyName
    postcode
    phoneNumber
    isDefault
  }
}
    `;
export const ShipmentCardFragmentDoc = gql`
    fragment ShipmentCard on ShipmentCardEntity {
  ...ShipmentCardMin
  id
  attributes {
    owner {
      data {
        id
      }
    }
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUserEntity {
  ...UserMin
  id
  attributes {
    confirmed
    firstName
    lastName
    phoneNumber
    createdAt
    blocked
    lead {
      data {
        ...LeadMin
      }
    }
    contact {
      data {
        ...ContactMin
      }
    }
    fullName
    jobTitle
    birthdayDate
    role {
      ...Role
    }
    tenant {
      data {
        ...Tenant
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    shipmentCards {
      data {
        ...ShipmentCard
      }
    }
  }
}
    `;
export const LeadFragmentDoc = gql`
    fragment Lead on LeadEntity {
  ...LeadMin
  id
  attributes {
    user {
      data {
        ...User
      }
    }
    leadOwner {
      data {
        ...User
      }
    }
    fileItems {
      data {
        id
      }
    }
    customFields
  }
}
    `;
export const CompanyMinFragmentDoc = gql`
    fragment CompanyMin on CompanyEntity {
  id
  attributes {
    name
    avatar {
      data {
        ...File
      }
    }
    uuid
    website
    address
    email
    phoneNumber
    type
    industry
    createdAt
    points
  }
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on ContactEntity {
  ...ContactMin
  ...ContactDynamicValues
  id
  attributes {
    lead {
      data {
        ...Lead
      }
    }
    company {
      data {
        ...CompanyMin
      }
    }
    leadOwner {
      data {
        ...User
      }
    }
    avatar {
      data {
        ...File
      }
    }
    fileItems {
      data {
        id
      }
    }
    customFields
  }
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on CompanyEntity {
  ...CompanyMin
  id
  attributes {
    contacts {
      data {
        ...ContactMin
      }
    }
    leadOwner {
      data {
        ...User
      }
    }
    fileItems {
      data {
        id
      }
    }
    customFields
  }
}
    `;
export const DealTransactionMinFragmentDoc = gql`
    fragment DealTransactionMin on DealTransactionEntity {
  id
  attributes {
    dueDate
    paid
    status
    summary
    createdAt
    updatedAt
    repetitive
    note
    paymentMethod
    dealTransactionId
  }
}
    `;
export const InvoiceMinFragmentDoc = gql`
    fragment InvoiceMin on InvoiceEntity {
  id
  attributes {
    createdAt
    uuid
    invoiceId
    clientMessage
    comment
    terms
  }
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on OrderEntity {
  ...OrderMin
  ...OrderWithItems
  id
  attributes {
    contact {
      data {
        ...Contact
      }
    }
    company {
      data {
        ...Company
      }
    }
    sales {
      data {
        ...User
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    discounts {
      data {
        ...DiscountMin
      }
    }
    files {
      data {
        ...File
      }
    }
    dealTransactions {
      data {
        ...DealTransactionMin
      }
    }
    invoice {
      data {
        ...InvoiceMin
      }
    }
  }
}
    `;
export const PaginationFragmentDoc = gql`
    fragment Pagination on Pagination {
  page
  pageCount
  pageSize
  total
}
    `;
export const MetaFragmentDoc = gql`
    fragment Meta on ResponseCollectionMeta {
  pagination {
    ...Pagination
  }
}
    `;
export const ProductInventoryItemEventFragmentDoc = gql`
    fragment ProductInventoryItemEvent on ProductInventoryItemEventEntity {
  id
  attributes {
    createdAt
    change
    remainingQuantity
    eventType
    relationId
    relationUuid
    itemCost
    receiveDate
    expiryDate
    memo
    laidAway
    itemVendor {
      data {
        ...Company
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    productInventoryItem {
      data {
        ...ProductInventoryItem
      }
    }
    addedBy {
      data {
        ...User
      }
    }
  }
}
    `;
export const ProductAttributeMinFragmentDoc = gql`
    fragment ProductAttributeMin on ProductAttributeEntity {
  id
  attributes {
    name
  }
}
    `;
export const ProductAttributeOptionMinFragmentDoc = gql`
    fragment ProductAttributeOptionMin on ProductAttributeOptionEntity {
  id
  attributes {
    name
    createdAt
  }
}
    `;
export const DesignStyleFragmentDoc = gql`
    fragment DesignStyle on DesignStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const ShankStyleFragmentDoc = gql`
    fragment ShankStyle on ShankStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const SizeFragmentDoc = gql`
    fragment Size on SizeEntity {
  id
  attributes {
    name
  }
}
    `;
export const JewelryProductTypeFragmentDoc = gql`
    fragment JewelryProductType on JewelryProductTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const SpecificTypeFragmentDoc = gql`
    fragment SpecificType on SpecificTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const EngravingTypeFragmentDoc = gql`
    fragment EngravingType on EngravingTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const TimePeriodFragmentDoc = gql`
    fragment TimePeriod on TimePeriodEntity {
  id
  attributes {
    name
  }
}
    `;
export const MetalFinishTypeFragmentDoc = gql`
    fragment MetalFinishType on MetalFinishTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const MaterialGradeFragmentDoc = gql`
    fragment MaterialGrade on MaterialGradeEntity {
  id
  attributes {
    name
  }
}
    `;
export const MetalTypeFragmentDoc = gql`
    fragment MetalType on MetalTypeEntity {
  id
  attributes {
    name
    materialGrades {
      data {
        ...MaterialGrade
      }
    }
  }
}
    `;
export const JewelryGenderTypeFragmentDoc = gql`
    fragment JewelryGenderType on GenderTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const JewelryConditionTypeFragmentDoc = gql`
    fragment JewelryConditionType on ConditionTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const PlattingTypeFragmentDoc = gql`
    fragment PlattingType on PlattingTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const ManufacturingProcessFragmentDoc = gql`
    fragment ManufacturingProcess on ManufacturingProcessEntity {
  id
  attributes {
    name
  }
}
    `;
export const PieceFragmentDoc = gql`
    fragment Piece on PieceEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneTypeFragmentDoc = gql`
    fragment StoneType on StoneTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneShapeFragmentDoc = gql`
    fragment StoneShape on StoneShapeEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneClarityFragmentDoc = gql`
    fragment StoneClarity on StoneClarityEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneClarityEnhancementFragmentDoc = gql`
    fragment StoneClarityEnhancement on StoneClarityEnhancementEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneClarityTypeFragmentDoc = gql`
    fragment StoneClarityType on StoneClarityTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneColorDominantFragmentDoc = gql`
    fragment StoneColorDominant on StoneColorDominantEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneColorIntensityFragmentDoc = gql`
    fragment StoneColorIntensity on StoneColorIntensityEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneColorSecondaryFragmentDoc = gql`
    fragment StoneColorSecondary on StoneColorSecondaryEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneColorMinFragmentDoc = gql`
    fragment StoneColorMin on StoneColorEntity {
  id
  attributes {
    colorDominant {
      data {
        ...StoneColorDominant
      }
    }
    colorDominantTo {
      data {
        ...StoneColorDominant
      }
    }
    colorIntensity {
      data {
        ...StoneColorIntensity
      }
    }
    diamondColor
    colorSecondary {
      data {
        ...StoneColorSecondary
      }
    }
    colorSecondaryTo {
      data {
        ...StoneColorSecondary
      }
    }
    colorDominant {
      data {
        ...StoneColorDominant
      }
    }
    colorDominantTo {
      data {
        ...StoneColorDominant
      }
    }
    colorIntensityTo {
      data {
        ...StoneColorIntensity
      }
    }
  }
}
    `;
export const StoneColorEnhancementFragmentDoc = gql`
    fragment StoneColorEnhancement on StoneColorEnhancementEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneCutFragmentDoc = gql`
    fragment StoneCut on StoneCutEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneCutStyleFragmentDoc = gql`
    fragment StoneCutStyle on StoneCutStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneFluorescenceFragmentDoc = gql`
    fragment StoneFluorescence on StoneFluorescenceEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneGeographicFragmentDoc = gql`
    fragment StoneGeographic on StoneGeographicEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneTreatmentFragmentDoc = gql`
    fragment StoneTreatment on StoneTreatmentEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneTransparencyFragmentDoc = gql`
    fragment StoneTransparency on StoneTransparencyEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneToneFragmentDoc = gql`
    fragment StoneTone on StoneToneEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneSymmetryFragmentDoc = gql`
    fragment StoneSymmetry on StoneSymmetryEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneSettingNameFragmentDoc = gql`
    fragment StoneSettingName on StoneSettingNameEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneSettingFamilyFragmentDoc = gql`
    fragment StoneSettingFamily on StoneSettingFamilyEntity {
  id
  attributes {
    name
  }
}
    `;
export const StonePolishFragmentDoc = gql`
    fragment StonePolish on StonePolishEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneOriginFragmentDoc = gql`
    fragment StoneOrigin on StoneOriginEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneIntensityFragmentDoc = gql`
    fragment StoneIntensity on StoneIntensityEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneGradingReportFragmentDoc = gql`
    fragment StoneGradingReport on StoneGradingReportEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneGirdleThicknessFragmentDoc = gql`
    fragment StoneGirdleThickness on StoneGirdleThicknessEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneGirdleFinishFragmentDoc = gql`
    fragment StoneGirdleFinish on StoneGirdleFinishEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneHueFragmentDoc = gql`
    fragment StoneHue on StoneHueEntity {
  id
  attributes {
    name
  }
}
    `;
export const StoneMinFragmentDoc = gql`
    fragment StoneMin on StoneEntity {
  id
  attributes {
    type
    quantity
    uuid
    stoneType {
      data {
        ...StoneType
      }
    }
    stoneShape {
      data {
        ...StoneShape
      }
    }
    clarity {
      data {
        ...StoneClarity
      }
    }
    clarityEnhancement {
      data {
        ...StoneClarityEnhancement
      }
    }
    clarityType {
      data {
        ...StoneClarityType
      }
    }
    color {
      data {
        ...StoneColorMin
      }
    }
    colorEnhancement {
      data {
        ...StoneColorEnhancement
      }
    }
    cut {
      data {
        ...StoneCut
      }
    }
    cutStyleCrown {
      data {
        ...StoneCutStyle
      }
    }
    cutStylePavilion {
      data {
        ...StoneCutStyle
      }
    }
    fluorescenceLong {
      data {
        ...StoneFluorescence
      }
    }
    fluorescenceShort {
      data {
        ...StoneFluorescence
      }
    }
    geographic {
      data {
        ...StoneGeographic
      }
    }
    treatment {
      data {
        ...StoneTreatment
      }
    }
    transparency {
      data {
        ...StoneTransparency
      }
    }
    tone {
      data {
        ...StoneTone
      }
    }
    symmetry {
      data {
        ...StoneSymmetry
      }
    }
    settingName {
      data {
        ...StoneSettingName
      }
    }
    settingMaterial {
      data {
        ...MetalType
      }
    }
    settingMaterialGrade {
      data {
        ...MaterialGrade
      }
    }
    settingFamily {
      data {
        ...StoneSettingFamily
      }
    }
    reportNumber
    polish {
      data {
        ...StonePolish
      }
    }
    origin {
      data {
        ...StoneOrigin
      }
    }
    intensity {
      data {
        ...StoneIntensity
      }
    }
    inscription
    gradingReportBy {
      data {
        ...StoneGradingReport
      }
    }
    girdleThickness {
      data {
        ...StoneGirdleThickness
      }
    }
    girdleFinish {
      data {
        ...StoneGirdleFinish
      }
    }
    fluorescenceLongHue {
      data {
        ...StoneHue
      }
    }
    fluorescenceShortHue {
      data {
        ...StoneHue
      }
    }
    issuedDate
    report {
      data {
        ...File
      }
    }
    weight {
      data {
        ...Weight
      }
    }
  }
}
    `;
export const JewelryProductMinFragmentDoc = gql`
    fragment JewelryProductMin on JewelryProductEntity {
  id
  attributes {
    uuid
    name
    marginCost
    files {
      data {
        ...File
      }
    }
    description
    createdAt
    barcode
    defaultPrice
    designStyle {
      data {
        ...DesignStyle
      }
    }
    shankStyle {
      data {
        ...ShankStyle
      }
    }
    size {
      data {
        ...Size
      }
    }
    model
    jewelryProductType {
      data {
        ...JewelryProductType
      }
    }
    specificType {
      data {
        ...SpecificType
      }
    }
    engravingType {
      data {
        ...EngravingType
      }
    }
    timePeriod {
      data {
        ...TimePeriod
      }
    }
    metalFinishType {
      data {
        ...MetalFinishType
      }
    }
    metalType {
      data {
        ...MetalType
      }
    }
    materialGradeType {
      data {
        ...MaterialGrade
      }
    }
    genderType {
      data {
        ...JewelryGenderType
      }
    }
    conditionType {
      data {
        ...JewelryConditionType
      }
    }
    platting {
      data {
        ...PlattingType
      }
    }
    process {
      data {
        ...ManufacturingProcess
      }
    }
    pieces {
      data {
        ...Piece
      }
    }
    serialNumber
    brand {
      data {
        ...ProductBrand
      }
    }
    rentableData {
      data {
        ...RentableData
      }
    }
    partsWarranty
    returnable
    bundleUseOnly
    businessUseOnly
    laborWarranty
    packagingProduct
    tagProductName
    expiryDate
    dimension {
      data {
        ...Dimension
      }
    }
    weight {
      data {
        ...Weight
      }
    }
    stones {
      data {
        ...StoneMin
      }
    }
    SKU
    ISBN
    MPN
    UPC
    EAN
  }
}
    `;
export const ProductAttributeOptionFragmentDoc = gql`
    fragment ProductAttributeOption on ProductAttributeOptionEntity {
  ...ProductAttributeOptionMin
  id
  attributes {
    productAttribute {
      data {
        ...ProductAttributeMin
      }
    }
    jewelryProducts {
      data {
        ...JewelryProductMin
      }
    }
    products {
      data {
        ...ProductMin
      }
    }
  }
}
    `;
export const ProductAttributeFragmentDoc = gql`
    fragment ProductAttribute on ProductAttributeEntity {
  ...ProductAttributeMin
  id
  attributes {
    productAttributeOptions {
      data {
        ...ProductAttributeOption
      }
    }
    productTypes {
      data {
        ...ProductType
      }
    }
    jewelryProductTypes {
      data {
        ...JewelryProductType
      }
    }
  }
}
    `;
export const ProductGroupMinFragmentDoc = gql`
    fragment ProductGroupMin on ProductGroupEntity {
  id
  attributes {
    name
    description
    createdAt
    uuid
  }
}
    `;
export const ProductGroupAttributeMinFragmentDoc = gql`
    fragment ProductGroupAttributeMin on ProductGroupAttributeEntity {
  id
  attributes {
    name
  }
}
    `;
export const ProductGroupAttributeOptionMinFragmentDoc = gql`
    fragment ProductGroupAttributeOptionMin on ProductGroupAttributeOptionEntity {
  id
  attributes {
    name
    createdAt
  }
}
    `;
export const ProductGroupAttributeFragmentDoc = gql`
    fragment ProductGroupAttribute on ProductGroupAttributeEntity {
  ...ProductGroupAttributeMin
  id
  attributes {
    productGroupAttributeOptions {
      data {
        ...ProductGroupAttributeOptionMin
      }
    }
  }
}
    `;
export const ProductGroupAttributeOptionFragmentDoc = gql`
    fragment ProductGroupAttributeOption on ProductGroupAttributeOptionEntity {
  ...ProductGroupAttributeOptionMin
  id
  attributes {
    productGroupAttribute {
      data {
        ...ProductGroupAttributeMin
      }
    }
  }
}
    `;
export const ProductGroupItemFragmentDoc = gql`
    fragment ProductGroupItem on ProductGroupItemEntity {
  id
  attributes {
    product {
      data {
        ...Product
      }
    }
    productGroupAttributeOptions {
      data {
        ...ProductGroupAttributeOption
      }
    }
    productGroup {
      data {
        ...ProductGroupMin
      }
    }
  }
}
    `;
export const ProductGroupFragmentDoc = gql`
    fragment ProductGroup on ProductGroupEntity {
  ...ProductGroupMin
  id
  attributes {
    productGroupAttributes {
      data {
        ...ProductGroupAttribute
      }
    }
    productGroupItems {
      data {
        ...ProductGroupItem
      }
    }
  }
}
    `;
export const QuestionMinFragmentDoc = gql`
    fragment QuestionMin on QuestionEntity {
  id
  attributes {
    createdAt
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on QuestionEntity {
  ...QuestionMin
  id
  attributes {
    title
    answer
    active
    owner {
      data {
        id
      }
    }
    createdAt
  }
}
    `;
export const JewelryProductFragmentDoc = gql`
    fragment JewelryProduct on JewelryProductEntity {
  ...JewelryProductMin
  id
  attributes {
    productInventoryItems {
      data {
        ...ProductInventoryItem
      }
    }
    productAttributeOptions {
      data {
        ...ProductAttributeOption
      }
    }
  }
}
    `;
export const StoneFragmentDoc = gql`
    fragment Stone on StoneEntity {
  id
  ...StoneMin
  attributes {
    jewelryProduct {
      data {
        ...JewelryProduct
      }
    }
  }
}
    `;
export const StoneColorFragmentDoc = gql`
    fragment StoneColor on StoneColorEntity {
  id
  attributes {
    uuid
    colorDominant {
      data {
        ...StoneColorDominant
      }
    }
    colorDominantTo {
      data {
        ...StoneColorDominant
      }
    }
    colorIntensity {
      data {
        ...StoneColorIntensity
      }
    }
    colorIntensityTo {
      data {
        ...StoneColorIntensity
      }
    }
    diamondColor
    colorSecondary {
      data {
        ...StoneColorSecondary
      }
    }
    colorSecondaryTo {
      data {
        ...StoneColorSecondary
      }
    }
    colorDominant {
      data {
        ...StoneColorDominant
      }
    }
    colorDominantTo {
      data {
        ...StoneColorDominant
      }
    }
    stone {
      data {
        ...Stone
      }
    }
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  title
  value
}
    `;
export const ReviewSectionFragmentDoc = gql`
    fragment ReviewSection on ComponentUiCustomerReviewSection {
  id
  title
  description
  reviews(pagination: {limit: 100}) {
    id
    name
    text
    rating
  }
  visible
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  visible
  heading {
    id
    title
    subtitle
    description
    media {
      data {
        ...File
      }
    }
  }
  button {
    id
    title
    url
    target
  }
}
    `;
export const ExtendedSectionFragmentDoc = gql`
    fragment ExtendedSection on ComponentUiExtendedSection {
  id
  visible
  heading {
    id
    title
    subtitle
  }
  media {
    data {
      ...File
    }
  }
  button {
    id
    title
    url
    icon
  }
  image {
    id
    title
    subtitle
    media {
      data {
        ...File
      }
    }
  }
}
    `;
export const SectionsVisibilityFragmentDoc = gql`
    fragment SectionsVisibility on ComponentUiSectionsVisibility {
  id
  pageTitle
  isFooterVisible
  isCustomerReviewVisible
  isFollowUsVisible
  isSubscribeNewsLetterVisible
  isBlogVisible
}
    `;
export const ProductTypesVisibilityFragmentDoc = gql`
    fragment ProductTypesVisibility on ComponentUiProductTypesVisibility {
  id
  Product
  Class
  Service
  Membership
  CompositeProduct
}
    `;
export const ShipmentCostFragmentDoc = gql`
    fragment ShipmentCost on ComponentUiShipmentCost {
  id
  isFree
  shipmentPrice
  isFreeAfterAmount
  freeShipmentAfter
}
    `;
export const WebsiteContactsFragmentDoc = gql`
    fragment WebsiteContacts on ComponentUiWebsiteContacts {
  id
  email
  phoneNumber
  address
}
    `;
export const WebsiteWorkingHoursFragmentDoc = gql`
    fragment WebsiteWorkingHours on ComponentUiWorkingHours {
  id
  monday
  tuesday
  wednesday
  thursday
  friday
  saturday
  sunday
}
    `;
export const WebsiteFragmentDoc = gql`
    fragment Website on WebsiteEntity {
  id
  attributes {
    tenant {
      data {
        ...Tenant
      }
    }
    subdomain
    privacyPolicy {
      ...Paragraph
    }
    termsConditions {
      ...Paragraph
    }
    customerReview {
      ...ReviewSection
    }
    shopHero {
      ...Section
    }
    blogHero {
      ...Section
    }
    initialSettings {
      ...ExtendedSection
    }
    footer {
      ...ExtendedSection
    }
    subscribeNewsletter {
      ...ExtendedSection
    }
    followUs {
      ...ExtendedSection
    }
    hero {
      ...ExtendedSection
    }
    services {
      ...ExtendedSection
    }
    aboutUs {
      ...ExtendedSection
    }
    aboutUsHero {
      ...ExtendedSection
    }
    statistics {
      ...ExtendedSection
    }
    benefits {
      ...ExtendedSection
    }
    aboutUsHero {
      ...ExtendedSection
    }
    statistics {
      ...ExtendedSection
    }
    benefits {
      ...ExtendedSection
    }
    faqHero {
      ...ExtendedSection
    }
    sectionsVisibility {
      ...SectionsVisibility
    }
    ProductTypesVisibility {
      ...ProductTypesVisibility
    }
    shipmentCost {
      ...ShipmentCost
    }
    websiteContacts {
      ...WebsiteContacts
    }
    workingHours {
      ...WebsiteWorkingHours
    }
  }
}
    `;
export const ArticleDocument = gql`
    query article($id: ID!) {
  article(id: $id) {
    data {
      ...Article
    }
  }
}
    ${ArticleFragmentDoc}
${ArticleMinFragmentDoc}
${FileFragmentDoc}`;
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query articles($filters: ArticleFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  articles(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Article
    }
    meta {
      ...Meta
    }
  }
}
    ${ArticleFragmentDoc}
${ArticleMinFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  changePassword(
    currentPassword: $currentPassword
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export const ConfirmEmailDocument = gql`
    mutation confirmEmail($confirmation: String!) {
  emailConfirmation(confirmation: $confirmation) {
    jwt
    user {
      id
    }
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
      username
      email
      confirmed
      blocked
      role {
        id
        name
        description
        type
      }
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const RegisterCustomerDocument = gql`
    mutation registerCustomer($input: CustomerUserRegisterInput!) {
  registerCustomer(input: $input) {
    jwt
    user {
      id
      username
      email
      confirmed
      blocked
      role {
        id
        name
        description
        type
      }
    }
  }
}
    `;
export type RegisterCustomerMutationFn = Apollo.MutationFunction<RegisterCustomerMutation, RegisterCustomerMutationVariables>;
export function useRegisterCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCustomerMutation, RegisterCustomerMutationVariables>(RegisterCustomerDocument, options);
      }
export type RegisterCustomerMutationHookResult = ReturnType<typeof useRegisterCustomerMutation>;
export type RegisterCustomerMutationResult = Apollo.MutationResult<RegisterCustomerMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    code: $code
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
    user {
      id
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const AuthLayoutContentDocument = gql`
    query authLayoutContent {
  authLayout {
    data {
      attributes {
        authContent {
          ...AuthContent
        }
      }
    }
  }
}
    ${AuthContentFragmentDoc}
${FileFragmentDoc}
${HeadlineFragmentDoc}`;
export function useAuthLayoutContentQuery(baseOptions?: Apollo.QueryHookOptions<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>(AuthLayoutContentDocument, options);
      }
export function useAuthLayoutContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>(AuthLayoutContentDocument, options);
        }
export type AuthLayoutContentQueryHookResult = ReturnType<typeof useAuthLayoutContentQuery>;
export type AuthLayoutContentLazyQueryHookResult = ReturnType<typeof useAuthLayoutContentLazyQuery>;
export type AuthLayoutContentQueryResult = Apollo.QueryResult<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>;
export const AuthLayoutHeadlineDocument = gql`
    query authLayoutHeadline {
  authLayout {
    data {
      attributes {
        headline {
          ...Headline
        }
      }
    }
  }
}
    ${HeadlineFragmentDoc}`;
export function useAuthLayoutHeadlineQuery(baseOptions?: Apollo.QueryHookOptions<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>(AuthLayoutHeadlineDocument, options);
      }
export function useAuthLayoutHeadlineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>(AuthLayoutHeadlineDocument, options);
        }
export type AuthLayoutHeadlineQueryHookResult = ReturnType<typeof useAuthLayoutHeadlineQuery>;
export type AuthLayoutHeadlineLazyQueryHookResult = ReturnType<typeof useAuthLayoutHeadlineLazyQuery>;
export type AuthLayoutHeadlineQueryResult = Apollo.QueryResult<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${LeadMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PasswordTokenValidDocument = gql`
    query passwordTokenValid($code: String!) {
  passwordTokenValid(code: $code) {
    isValid
  }
}
    `;
export function usePasswordTokenValidQuery(baseOptions: Apollo.QueryHookOptions<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>(PasswordTokenValidDocument, options);
      }
export function usePasswordTokenValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>(PasswordTokenValidDocument, options);
        }
export type PasswordTokenValidQueryHookResult = ReturnType<typeof usePasswordTokenValidQuery>;
export type PasswordTokenValidLazyQueryHookResult = ReturnType<typeof usePasswordTokenValidLazyQuery>;
export type PasswordTokenValidQueryResult = Apollo.QueryResult<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>;
export const BusinessLocationDocument = gql`
    query businessLocation($id: ID!) {
  businessLocation(id: $id) {
    data {
      ...BusinessLocation
    }
  }
}
    ${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export function useBusinessLocationQuery(baseOptions: Apollo.QueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
      }
export function useBusinessLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
        }
export type BusinessLocationQueryHookResult = ReturnType<typeof useBusinessLocationQuery>;
export type BusinessLocationLazyQueryHookResult = ReturnType<typeof useBusinessLocationLazyQuery>;
export type BusinessLocationQueryResult = Apollo.QueryResult<BusinessLocationQuery, BusinessLocationQueryVariables>;
export const BusinessLocationsDocument = gql`
    query businessLocations($filters: BusinessLocationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  businessLocations(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...BusinessLocation
    }
    meta {
      ...Meta
    }
  }
}
    ${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useBusinessLocationsQuery(baseOptions?: Apollo.QueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
      }
export function useBusinessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
        }
export type BusinessLocationsQueryHookResult = ReturnType<typeof useBusinessLocationsQuery>;
export type BusinessLocationsLazyQueryHookResult = ReturnType<typeof useBusinessLocationsLazyQuery>;
export type BusinessLocationsQueryResult = Apollo.QueryResult<BusinessLocationsQuery, BusinessLocationsQueryVariables>;
export const SubLocationsDocument = gql`
    query subLocations($filters: SublocationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  sublocations(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SubLocation
    }
    meta {
      ...Meta
    }
  }
}
    ${SubLocationFragmentDoc}
${SubLocationMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSubLocationsQuery(baseOptions?: Apollo.QueryHookOptions<SubLocationsQuery, SubLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubLocationsQuery, SubLocationsQueryVariables>(SubLocationsDocument, options);
      }
export function useSubLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubLocationsQuery, SubLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubLocationsQuery, SubLocationsQueryVariables>(SubLocationsDocument, options);
        }
export type SubLocationsQueryHookResult = ReturnType<typeof useSubLocationsQuery>;
export type SubLocationsLazyQueryHookResult = ReturnType<typeof useSubLocationsLazyQuery>;
export type SubLocationsQueryResult = Apollo.QueryResult<SubLocationsQuery, SubLocationsQueryVariables>;
export const ClassByUuidDocument = gql`
    query classByUuid($uuid: String!) {
  classes(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}`;
export function useClassByUuidQuery(baseOptions: Apollo.QueryHookOptions<ClassByUuidQuery, ClassByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassByUuidQuery, ClassByUuidQueryVariables>(ClassByUuidDocument, options);
      }
export function useClassByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassByUuidQuery, ClassByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassByUuidQuery, ClassByUuidQueryVariables>(ClassByUuidDocument, options);
        }
export type ClassByUuidQueryHookResult = ReturnType<typeof useClassByUuidQuery>;
export type ClassByUuidLazyQueryHookResult = ReturnType<typeof useClassByUuidLazyQuery>;
export type ClassByUuidQueryResult = Apollo.QueryResult<ClassByUuidQuery, ClassByUuidQueryVariables>;
export const ClassPerformersDocument = gql`
    query classPerformers($filters: ClassPerformerFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  classPerformers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ClassPerformer
    }
    meta {
      ...Meta
    }
  }
}
    ${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useClassPerformersQuery(baseOptions?: Apollo.QueryHookOptions<ClassPerformersQuery, ClassPerformersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassPerformersQuery, ClassPerformersQueryVariables>(ClassPerformersDocument, options);
      }
export function useClassPerformersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassPerformersQuery, ClassPerformersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassPerformersQuery, ClassPerformersQueryVariables>(ClassPerformersDocument, options);
        }
export type ClassPerformersQueryHookResult = ReturnType<typeof useClassPerformersQuery>;
export type ClassPerformersLazyQueryHookResult = ReturnType<typeof useClassPerformersLazyQuery>;
export type ClassPerformersQueryResult = Apollo.QueryResult<ClassPerformersQuery, ClassPerformersQueryVariables>;
export const ClassesDocument = gql`
    query classes($filters: ClassFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  classes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Class
    }
    meta {
      ...Meta
    }
  }
}
    ${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useClassesQuery(baseOptions?: Apollo.QueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
      }
export function useClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
        }
export type ClassesQueryHookResult = ReturnType<typeof useClassesQuery>;
export type ClassesLazyQueryHookResult = ReturnType<typeof useClassesLazyQuery>;
export type ClassesQueryResult = Apollo.QueryResult<ClassesQuery, ClassesQueryVariables>;
export const ClassesPriceRangeDocument = gql`
    query classesPriceRange($id: ID!) {
  classesPriceRange(id: $id) {
    minPrice
    maxPrice
  }
}
    `;
export function useClassesPriceRangeQuery(baseOptions: Apollo.QueryHookOptions<ClassesPriceRangeQuery, ClassesPriceRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesPriceRangeQuery, ClassesPriceRangeQueryVariables>(ClassesPriceRangeDocument, options);
      }
export function useClassesPriceRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesPriceRangeQuery, ClassesPriceRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesPriceRangeQuery, ClassesPriceRangeQueryVariables>(ClassesPriceRangeDocument, options);
        }
export type ClassesPriceRangeQueryHookResult = ReturnType<typeof useClassesPriceRangeQuery>;
export type ClassesPriceRangeLazyQueryHookResult = ReturnType<typeof useClassesPriceRangeLazyQuery>;
export type ClassesPriceRangeQueryResult = Apollo.QueryResult<ClassesPriceRangeQuery, ClassesPriceRangeQueryVariables>;
export const CompositeProductByUuidDocument = gql`
    query compositeProductByUuid($uuid: String!) {
  compositeProducts(filters: {uuid: {eq: $uuid}}) {
    data {
      ...CompositeProduct
    }
  }
}
    ${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export function useCompositeProductByUuidQuery(baseOptions: Apollo.QueryHookOptions<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>(CompositeProductByUuidDocument, options);
      }
export function useCompositeProductByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>(CompositeProductByUuidDocument, options);
        }
export type CompositeProductByUuidQueryHookResult = ReturnType<typeof useCompositeProductByUuidQuery>;
export type CompositeProductByUuidLazyQueryHookResult = ReturnType<typeof useCompositeProductByUuidLazyQuery>;
export type CompositeProductByUuidQueryResult = Apollo.QueryResult<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>;
export const CompositeProductLocationInfosDocument = gql`
    query compositeProductLocationInfos($filters: CompositeProductLocationInfoFiltersInput, $pagination: PaginationArg = {}, $sort: [String], $businessLocationId: Int) {
  compositeProductLocationInfos(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...CompositeProductLocationInfoWithQuantity
    }
    meta {
      ...Meta
    }
  }
}
    ${CompositeProductLocationInfoWithQuantityFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCompositeProductLocationInfosQuery(baseOptions?: Apollo.QueryHookOptions<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>(CompositeProductLocationInfosDocument, options);
      }
export function useCompositeProductLocationInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>(CompositeProductLocationInfosDocument, options);
        }
export type CompositeProductLocationInfosQueryHookResult = ReturnType<typeof useCompositeProductLocationInfosQuery>;
export type CompositeProductLocationInfosLazyQueryHookResult = ReturnType<typeof useCompositeProductLocationInfosLazyQuery>;
export type CompositeProductLocationInfosQueryResult = Apollo.QueryResult<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>;
export const CompositeProductsDocument = gql`
    query compositeProducts($filters: CompositeProductFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  compositeProducts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...CompositeProduct
    }
    meta {
      ...Meta
    }
  }
}
    ${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCompositeProductsQuery(baseOptions?: Apollo.QueryHookOptions<CompositeProductsQuery, CompositeProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductsQuery, CompositeProductsQueryVariables>(CompositeProductsDocument, options);
      }
export function useCompositeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductsQuery, CompositeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductsQuery, CompositeProductsQueryVariables>(CompositeProductsDocument, options);
        }
export type CompositeProductsQueryHookResult = ReturnType<typeof useCompositeProductsQuery>;
export type CompositeProductsLazyQueryHookResult = ReturnType<typeof useCompositeProductsLazyQuery>;
export type CompositeProductsQueryResult = Apollo.QueryResult<CompositeProductsQuery, CompositeProductsQueryVariables>;
export const CompositeProductsPriceRangeDocument = gql`
    query compositeProductsPriceRange($id: ID!) {
  compositeProductsPriceRange(id: $id) {
    minPrice
    maxPrice
  }
}
    `;
export function useCompositeProductsPriceRangeQuery(baseOptions: Apollo.QueryHookOptions<CompositeProductsPriceRangeQuery, CompositeProductsPriceRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductsPriceRangeQuery, CompositeProductsPriceRangeQueryVariables>(CompositeProductsPriceRangeDocument, options);
      }
export function useCompositeProductsPriceRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductsPriceRangeQuery, CompositeProductsPriceRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductsPriceRangeQuery, CompositeProductsPriceRangeQueryVariables>(CompositeProductsPriceRangeDocument, options);
        }
export type CompositeProductsPriceRangeQueryHookResult = ReturnType<typeof useCompositeProductsPriceRangeQuery>;
export type CompositeProductsPriceRangeLazyQueryHookResult = ReturnType<typeof useCompositeProductsPriceRangeLazyQuery>;
export type CompositeProductsPriceRangeQueryResult = Apollo.QueryResult<CompositeProductsPriceRangeQuery, CompositeProductsPriceRangeQueryVariables>;
export const UpdateDiscountDocument = gql`
    mutation updateDiscount($id: ID!, $input: DiscountInput!) {
  updateDiscount(id: $id, data: $input) {
    data {
      ...Discount
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}`;
export type UpdateDiscountMutationFn = Apollo.MutationFunction<UpdateDiscountMutation, UpdateDiscountMutationVariables>;
export function useUpdateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscountMutation, UpdateDiscountMutationVariables>(UpdateDiscountDocument, options);
      }
export type UpdateDiscountMutationHookResult = ReturnType<typeof useUpdateDiscountMutation>;
export type UpdateDiscountMutationResult = Apollo.MutationResult<UpdateDiscountMutation>;
export const DiscountsDocument = gql`
    query discounts($filters: DiscountFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  discounts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Discount
    }
    meta {
      ...Meta
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, options);
      }
export function useDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, options);
        }
export type DiscountsQueryHookResult = ReturnType<typeof useDiscountsQuery>;
export type DiscountsLazyQueryHookResult = ReturnType<typeof useDiscountsLazyQuery>;
export type DiscountsQueryResult = Apollo.QueryResult<DiscountsQuery, DiscountsQueryVariables>;
export const DeleteUploadFileDocument = gql`
    mutation deleteUploadFile($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type DeleteUploadFileMutationFn = Apollo.MutationFunction<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>;
export function useDeleteUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>(DeleteUploadFileDocument, options);
      }
export type DeleteUploadFileMutationHookResult = ReturnType<typeof useDeleteUploadFileMutation>;
export type DeleteUploadFileMutationResult = Apollo.MutationResult<DeleteUploadFileMutation>;
export const MultipleUploadDocument = gql`
    mutation multipleUpload($files: [Upload]!, $ref: String, $refId: ID, $field: String) {
  multipleUpload(files: $files, ref: $ref, refId: $refId, field: $field) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type MultipleUploadMutationFn = Apollo.MutationFunction<MultipleUploadMutation, MultipleUploadMutationVariables>;
export function useMultipleUploadMutation(baseOptions?: Apollo.MutationHookOptions<MultipleUploadMutation, MultipleUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultipleUploadMutation, MultipleUploadMutationVariables>(MultipleUploadDocument, options);
      }
export type MultipleUploadMutationHookResult = ReturnType<typeof useMultipleUploadMutation>;
export type MultipleUploadMutationResult = Apollo.MutationResult<MultipleUploadMutation>;
export const ProcessingFileUploadingDocument = gql`
    mutation processingFileUploading($input: ProcessingFileUploadingArgInput!) {
  processingFileUploading(input: $input) {
    resultObj
  }
}
    `;
export type ProcessingFileUploadingMutationFn = Apollo.MutationFunction<ProcessingFileUploadingMutation, ProcessingFileUploadingMutationVariables>;
export function useProcessingFileUploadingMutation(baseOptions?: Apollo.MutationHookOptions<ProcessingFileUploadingMutation, ProcessingFileUploadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessingFileUploadingMutation, ProcessingFileUploadingMutationVariables>(ProcessingFileUploadingDocument, options);
      }
export type ProcessingFileUploadingMutationHookResult = ReturnType<typeof useProcessingFileUploadingMutation>;
export type ProcessingFileUploadingMutationResult = Apollo.MutationResult<ProcessingFileUploadingMutation>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!, $ref: String, $refId: ID, $field: String) {
  upload(file: $file, ref: $ref, refId: $refId, field: $field) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export const CreateLeadDocument = gql`
    mutation createLead($input: LeadInput!) {
  createLead(data: $input) {
    data {
      ...Lead
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export type CreateLeadMutationFn = Apollo.MutationFunction<CreateLeadMutation, CreateLeadMutationVariables>;
export function useCreateLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateLeadMutation, CreateLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLeadMutation, CreateLeadMutationVariables>(CreateLeadDocument, options);
      }
export type CreateLeadMutationHookResult = ReturnType<typeof useCreateLeadMutation>;
export type CreateLeadMutationResult = Apollo.MutationResult<CreateLeadMutation>;
export const LeadByUuidDocument = gql`
    query leadByUuid($uuid: String!) {
  leads(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Lead
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export function useLeadByUuidQuery(baseOptions: Apollo.QueryHookOptions<LeadByUuidQuery, LeadByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadByUuidQuery, LeadByUuidQueryVariables>(LeadByUuidDocument, options);
      }
export function useLeadByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadByUuidQuery, LeadByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadByUuidQuery, LeadByUuidQueryVariables>(LeadByUuidDocument, options);
        }
export type LeadByUuidQueryHookResult = ReturnType<typeof useLeadByUuidQuery>;
export type LeadByUuidLazyQueryHookResult = ReturnType<typeof useLeadByUuidLazyQuery>;
export type LeadByUuidQueryResult = Apollo.QueryResult<LeadByUuidQuery, LeadByUuidQueryVariables>;
export const LeadsDocument = gql`
    query leads($filters: LeadFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  leads(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Lead
    }
    meta {
      ...Meta
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useLeadsQuery(baseOptions?: Apollo.QueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
      }
export function useLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
        }
export type LeadsQueryHookResult = ReturnType<typeof useLeadsQuery>;
export type LeadsLazyQueryHookResult = ReturnType<typeof useLeadsLazyQuery>;
export type LeadsQueryResult = Apollo.QueryResult<LeadsQuery, LeadsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($input: LocationInput!) {
  createLocation(data: $input) {
    data {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export const DeleteLocationDocument = gql`
    mutation deleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    data {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($id: ID!, $input: LocationInput!) {
  updateLocation(id: $id, data: $input) {
    data {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export const MembershipByUuidDocument = gql`
    query membershipByUuid($uuid: String!) {
  memberships(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Membership
    }
  }
}
    ${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}`;
export function useMembershipByUuidQuery(baseOptions: Apollo.QueryHookOptions<MembershipByUuidQuery, MembershipByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipByUuidQuery, MembershipByUuidQueryVariables>(MembershipByUuidDocument, options);
      }
export function useMembershipByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipByUuidQuery, MembershipByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipByUuidQuery, MembershipByUuidQueryVariables>(MembershipByUuidDocument, options);
        }
export type MembershipByUuidQueryHookResult = ReturnType<typeof useMembershipByUuidQuery>;
export type MembershipByUuidLazyQueryHookResult = ReturnType<typeof useMembershipByUuidLazyQuery>;
export type MembershipByUuidQueryResult = Apollo.QueryResult<MembershipByUuidQuery, MembershipByUuidQueryVariables>;
export const MembershipsDocument = gql`
    query memberships($filters: MembershipFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  memberships(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Membership
    }
    meta {
      ...Meta
    }
  }
}
    ${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
      }
export function useMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
        }
export type MembershipsQueryHookResult = ReturnType<typeof useMembershipsQuery>;
export type MembershipsLazyQueryHookResult = ReturnType<typeof useMembershipsLazyQuery>;
export type MembershipsQueryResult = Apollo.QueryResult<MembershipsQuery, MembershipsQueryVariables>;
export const MembershipsPriceRangeDocument = gql`
    query membershipsPriceRange($id: ID!) {
  membershipsPriceRange(id: $id) {
    minPrice
    maxPrice
  }
}
    `;
export function useMembershipsPriceRangeQuery(baseOptions: Apollo.QueryHookOptions<MembershipsPriceRangeQuery, MembershipsPriceRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsPriceRangeQuery, MembershipsPriceRangeQueryVariables>(MembershipsPriceRangeDocument, options);
      }
export function useMembershipsPriceRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsPriceRangeQuery, MembershipsPriceRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsPriceRangeQuery, MembershipsPriceRangeQueryVariables>(MembershipsPriceRangeDocument, options);
        }
export type MembershipsPriceRangeQueryHookResult = ReturnType<typeof useMembershipsPriceRangeQuery>;
export type MembershipsPriceRangeLazyQueryHookResult = ReturnType<typeof useMembershipsPriceRangeLazyQuery>;
export type MembershipsPriceRangeQueryResult = Apollo.QueryResult<MembershipsPriceRangeQuery, MembershipsPriceRangeQueryVariables>;
export const AddDiscountDocument = gql`
    mutation addDiscount($input: OrderDiscountInput!) {
  addDiscount(input: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceMinFragmentDoc}`;
export type AddDiscountMutationFn = Apollo.MutationFunction<AddDiscountMutation, AddDiscountMutationVariables>;
export function useAddDiscountMutation(baseOptions?: Apollo.MutationHookOptions<AddDiscountMutation, AddDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiscountMutation, AddDiscountMutationVariables>(AddDiscountDocument, options);
      }
export type AddDiscountMutationHookResult = ReturnType<typeof useAddDiscountMutation>;
export type AddDiscountMutationResult = Apollo.MutationResult<AddDiscountMutation>;
export const CreateClassOrderItemDocument = gql`
    mutation createClassOrderItem($input: ClassOrderItemInput!) {
  createClassOrderItem(data: $input) {
    data {
      ...ClassOrderItem
    }
  }
}
    ${ClassOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${OrderMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type CreateClassOrderItemMutationFn = Apollo.MutationFunction<CreateClassOrderItemMutation, CreateClassOrderItemMutationVariables>;
export function useCreateClassOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassOrderItemMutation, CreateClassOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassOrderItemMutation, CreateClassOrderItemMutationVariables>(CreateClassOrderItemDocument, options);
      }
export type CreateClassOrderItemMutationHookResult = ReturnType<typeof useCreateClassOrderItemMutation>;
export type CreateClassOrderItemMutationResult = Apollo.MutationResult<CreateClassOrderItemMutation>;
export const CreateCompositeProductOrderItemDocument = gql`
    mutation createCompositeProductOrderItem($input: CompositeProductOrderItemInput!) {
  createCompositeProductOrderItem(data: $input) {
    data {
      ...CompositeProductOrderItem
    }
  }
}
    ${CompositeProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateCompositeProductOrderItemMutationFn = Apollo.MutationFunction<CreateCompositeProductOrderItemMutation, CreateCompositeProductOrderItemMutationVariables>;
export function useCreateCompositeProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompositeProductOrderItemMutation, CreateCompositeProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompositeProductOrderItemMutation, CreateCompositeProductOrderItemMutationVariables>(CreateCompositeProductOrderItemDocument, options);
      }
export type CreateCompositeProductOrderItemMutationHookResult = ReturnType<typeof useCreateCompositeProductOrderItemMutation>;
export type CreateCompositeProductOrderItemMutationResult = Apollo.MutationResult<CreateCompositeProductOrderItemMutation>;
export const CreateMembershipOrderItemDocument = gql`
    mutation createMembershipOrderItem($input: MembershipOrderItemInput!) {
  createMembershipOrderItem(data: $input) {
    data {
      ...MembershipOrderItem
    }
  }
}
    ${MembershipOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}`;
export type CreateMembershipOrderItemMutationFn = Apollo.MutationFunction<CreateMembershipOrderItemMutation, CreateMembershipOrderItemMutationVariables>;
export function useCreateMembershipOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipOrderItemMutation, CreateMembershipOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMembershipOrderItemMutation, CreateMembershipOrderItemMutationVariables>(CreateMembershipOrderItemDocument, options);
      }
export type CreateMembershipOrderItemMutationHookResult = ReturnType<typeof useCreateMembershipOrderItemMutation>;
export type CreateMembershipOrderItemMutationResult = Apollo.MutationResult<CreateMembershipOrderItemMutation>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderInput!) {
  createOrder(data: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceMinFragmentDoc}`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export const CreateProductOrderItemDocument = gql`
    mutation createProductOrderItem($input: ProductOrderItemInput!) {
  createProductOrderItem(data: $input) {
    data {
      ...ProductOrderItem
    }
  }
}
    ${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateProductOrderItemMutationFn = Apollo.MutationFunction<CreateProductOrderItemMutation, CreateProductOrderItemMutationVariables>;
export function useCreateProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductOrderItemMutation, CreateProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductOrderItemMutation, CreateProductOrderItemMutationVariables>(CreateProductOrderItemDocument, options);
      }
export type CreateProductOrderItemMutationHookResult = ReturnType<typeof useCreateProductOrderItemMutation>;
export type CreateProductOrderItemMutationResult = Apollo.MutationResult<CreateProductOrderItemMutation>;
export const CreateServiceOrderItemDocument = gql`
    mutation createServiceOrderItem($input: ServiceOrderItemInput!) {
  createServiceOrderItem(data: $input) {
    data {
      ...ServiceOrderItem
    }
  }
}
    ${ServiceOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateServiceOrderItemMutationFn = Apollo.MutationFunction<CreateServiceOrderItemMutation, CreateServiceOrderItemMutationVariables>;
export function useCreateServiceOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceOrderItemMutation, CreateServiceOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceOrderItemMutation, CreateServiceOrderItemMutationVariables>(CreateServiceOrderItemDocument, options);
      }
export type CreateServiceOrderItemMutationHookResult = ReturnType<typeof useCreateServiceOrderItemMutation>;
export type CreateServiceOrderItemMutationResult = Apollo.MutationResult<CreateServiceOrderItemMutation>;
export const DeleteClassOrderItemDocument = gql`
    mutation deleteClassOrderItem($id: ID!) {
  deleteClassOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteClassOrderItemMutationFn = Apollo.MutationFunction<DeleteClassOrderItemMutation, DeleteClassOrderItemMutationVariables>;
export function useDeleteClassOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassOrderItemMutation, DeleteClassOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassOrderItemMutation, DeleteClassOrderItemMutationVariables>(DeleteClassOrderItemDocument, options);
      }
export type DeleteClassOrderItemMutationHookResult = ReturnType<typeof useDeleteClassOrderItemMutation>;
export type DeleteClassOrderItemMutationResult = Apollo.MutationResult<DeleteClassOrderItemMutation>;
export const DeleteCompositeProductOrderItemDocument = gql`
    mutation deleteCompositeProductOrderItem($id: ID!) {
  deleteCompositeProductOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteCompositeProductOrderItemMutationFn = Apollo.MutationFunction<DeleteCompositeProductOrderItemMutation, DeleteCompositeProductOrderItemMutationVariables>;
export function useDeleteCompositeProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompositeProductOrderItemMutation, DeleteCompositeProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompositeProductOrderItemMutation, DeleteCompositeProductOrderItemMutationVariables>(DeleteCompositeProductOrderItemDocument, options);
      }
export type DeleteCompositeProductOrderItemMutationHookResult = ReturnType<typeof useDeleteCompositeProductOrderItemMutation>;
export type DeleteCompositeProductOrderItemMutationResult = Apollo.MutationResult<DeleteCompositeProductOrderItemMutation>;
export const DeleteMembershipOrderItemDocument = gql`
    mutation deleteMembershipOrderItem($id: ID!) {
  deleteMembershipOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteMembershipOrderItemMutationFn = Apollo.MutationFunction<DeleteMembershipOrderItemMutation, DeleteMembershipOrderItemMutationVariables>;
export function useDeleteMembershipOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipOrderItemMutation, DeleteMembershipOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMembershipOrderItemMutation, DeleteMembershipOrderItemMutationVariables>(DeleteMembershipOrderItemDocument, options);
      }
export type DeleteMembershipOrderItemMutationHookResult = ReturnType<typeof useDeleteMembershipOrderItemMutation>;
export type DeleteMembershipOrderItemMutationResult = Apollo.MutationResult<DeleteMembershipOrderItemMutation>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($id: ID!) {
  deleteOrder(id: $id) {
    data {
      id
      attributes {
        orderId
      }
    }
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export const DeleteProductOrderItemDocument = gql`
    mutation deleteProductOrderItem($id: ID!) {
  deleteProductOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteProductOrderItemMutationFn = Apollo.MutationFunction<DeleteProductOrderItemMutation, DeleteProductOrderItemMutationVariables>;
export function useDeleteProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductOrderItemMutation, DeleteProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductOrderItemMutation, DeleteProductOrderItemMutationVariables>(DeleteProductOrderItemDocument, options);
      }
export type DeleteProductOrderItemMutationHookResult = ReturnType<typeof useDeleteProductOrderItemMutation>;
export type DeleteProductOrderItemMutationResult = Apollo.MutationResult<DeleteProductOrderItemMutation>;
export const DeleteServiceOrderItemDocument = gql`
    mutation deleteServiceOrderItem($id: ID!) {
  deleteServiceOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteServiceOrderItemMutationFn = Apollo.MutationFunction<DeleteServiceOrderItemMutation, DeleteServiceOrderItemMutationVariables>;
export function useDeleteServiceOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceOrderItemMutation, DeleteServiceOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceOrderItemMutation, DeleteServiceOrderItemMutationVariables>(DeleteServiceOrderItemDocument, options);
      }
export type DeleteServiceOrderItemMutationHookResult = ReturnType<typeof useDeleteServiceOrderItemMutation>;
export type DeleteServiceOrderItemMutationResult = Apollo.MutationResult<DeleteServiceOrderItemMutation>;
export const RemoveDiscountDocument = gql`
    mutation removeDiscount($input: OrderDiscountRemoveInput!) {
  removeDiscount(input: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceMinFragmentDoc}`;
export type RemoveDiscountMutationFn = Apollo.MutationFunction<RemoveDiscountMutation, RemoveDiscountMutationVariables>;
export function useRemoveDiscountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDiscountMutation, RemoveDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDiscountMutation, RemoveDiscountMutationVariables>(RemoveDiscountDocument, options);
      }
export type RemoveDiscountMutationHookResult = ReturnType<typeof useRemoveDiscountMutation>;
export type RemoveDiscountMutationResult = Apollo.MutationResult<RemoveDiscountMutation>;
export const UpdateClassOrderItemDocument = gql`
    mutation updateClassOrderItem($id: ID!, $input: ClassOrderItemInput!) {
  updateClassOrderItem(id: $id, data: $input) {
    data {
      ...ClassOrderItem
    }
  }
}
    ${ClassOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${OrderMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type UpdateClassOrderItemMutationFn = Apollo.MutationFunction<UpdateClassOrderItemMutation, UpdateClassOrderItemMutationVariables>;
export function useUpdateClassOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassOrderItemMutation, UpdateClassOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassOrderItemMutation, UpdateClassOrderItemMutationVariables>(UpdateClassOrderItemDocument, options);
      }
export type UpdateClassOrderItemMutationHookResult = ReturnType<typeof useUpdateClassOrderItemMutation>;
export type UpdateClassOrderItemMutationResult = Apollo.MutationResult<UpdateClassOrderItemMutation>;
export const UpdateCompositeProductOrderItemDocument = gql`
    mutation updateCompositeProductOrderItem($id: ID!, $input: CompositeProductOrderItemInput!) {
  updateCompositeProductOrderItem(id: $id, data: $input) {
    data {
      ...CompositeProductOrderItem
    }
  }
}
    ${CompositeProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateCompositeProductOrderItemMutationFn = Apollo.MutationFunction<UpdateCompositeProductOrderItemMutation, UpdateCompositeProductOrderItemMutationVariables>;
export function useUpdateCompositeProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompositeProductOrderItemMutation, UpdateCompositeProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompositeProductOrderItemMutation, UpdateCompositeProductOrderItemMutationVariables>(UpdateCompositeProductOrderItemDocument, options);
      }
export type UpdateCompositeProductOrderItemMutationHookResult = ReturnType<typeof useUpdateCompositeProductOrderItemMutation>;
export type UpdateCompositeProductOrderItemMutationResult = Apollo.MutationResult<UpdateCompositeProductOrderItemMutation>;
export const UpdateCustomerOrderDocument = gql`
    mutation updateCustomerOrder($input: UpdateCustomerOrderInput!) {
  updateCustomerOrder(input: $input) {
    data {
      ...OrderMin
    }
  }
}
    ${OrderMinFragmentDoc}`;
export type UpdateCustomerOrderMutationFn = Apollo.MutationFunction<UpdateCustomerOrderMutation, UpdateCustomerOrderMutationVariables>;
export function useUpdateCustomerOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerOrderMutation, UpdateCustomerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerOrderMutation, UpdateCustomerOrderMutationVariables>(UpdateCustomerOrderDocument, options);
      }
export type UpdateCustomerOrderMutationHookResult = ReturnType<typeof useUpdateCustomerOrderMutation>;
export type UpdateCustomerOrderMutationResult = Apollo.MutationResult<UpdateCustomerOrderMutation>;
export const UpdateMembershipOrderItemDocument = gql`
    mutation updateMembershipOrderItem($id: ID!, $input: MembershipOrderItemInput!) {
  updateMembershipOrderItem(id: $id, data: $input) {
    data {
      ...MembershipOrderItem
    }
  }
}
    ${MembershipOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}`;
export type UpdateMembershipOrderItemMutationFn = Apollo.MutationFunction<UpdateMembershipOrderItemMutation, UpdateMembershipOrderItemMutationVariables>;
export function useUpdateMembershipOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipOrderItemMutation, UpdateMembershipOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipOrderItemMutation, UpdateMembershipOrderItemMutationVariables>(UpdateMembershipOrderItemDocument, options);
      }
export type UpdateMembershipOrderItemMutationHookResult = ReturnType<typeof useUpdateMembershipOrderItemMutation>;
export type UpdateMembershipOrderItemMutationResult = Apollo.MutationResult<UpdateMembershipOrderItemMutation>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($id: ID!, $input: OrderInput!) {
  updateOrder(id: $id, data: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceMinFragmentDoc}`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export const UpdateProductOrderItemDocument = gql`
    mutation updateProductOrderItem($input: ProductOrderItemInput!, $id: ID!) {
  updateProductOrderItem(id: $id, data: $input) {
    data {
      ...ProductOrderItem
    }
  }
}
    ${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateProductOrderItemMutationFn = Apollo.MutationFunction<UpdateProductOrderItemMutation, UpdateProductOrderItemMutationVariables>;
export function useUpdateProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductOrderItemMutation, UpdateProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductOrderItemMutation, UpdateProductOrderItemMutationVariables>(UpdateProductOrderItemDocument, options);
      }
export type UpdateProductOrderItemMutationHookResult = ReturnType<typeof useUpdateProductOrderItemMutation>;
export type UpdateProductOrderItemMutationResult = Apollo.MutationResult<UpdateProductOrderItemMutation>;
export const UpdateServiceOrderItemDocument = gql`
    mutation updateServiceOrderItem($id: ID!, $input: ServiceOrderItemInput!) {
  updateServiceOrderItem(id: $id, data: $input) {
    data {
      ...ServiceOrderItem
    }
  }
}
    ${ServiceOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateServiceOrderItemMutationFn = Apollo.MutationFunction<UpdateServiceOrderItemMutation, UpdateServiceOrderItemMutationVariables>;
export function useUpdateServiceOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceOrderItemMutation, UpdateServiceOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceOrderItemMutation, UpdateServiceOrderItemMutationVariables>(UpdateServiceOrderItemDocument, options);
      }
export type UpdateServiceOrderItemMutationHookResult = ReturnType<typeof useUpdateServiceOrderItemMutation>;
export type UpdateServiceOrderItemMutationResult = Apollo.MutationResult<UpdateServiceOrderItemMutation>;
export const OrderByIdDocument = gql`
    query orderById($orderId: String!) {
  orders(filters: {orderId: {eq: $orderId}}) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceMinFragmentDoc}`;
export function useOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, options);
      }
export function useOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, options);
        }
export type OrderByIdQueryHookResult = ReturnType<typeof useOrderByIdQuery>;
export type OrderByIdLazyQueryHookResult = ReturnType<typeof useOrderByIdLazyQuery>;
export type OrderByIdQueryResult = Apollo.QueryResult<OrderByIdQuery, OrderByIdQueryVariables>;
export const OrderByIdWithQuantitiesDocument = gql`
    query orderByIdWithQuantities($orderId: String!, $businessLocationId: Int) {
  orders(filters: {orderId: {eq: $orderId}}) {
    data {
      ...OrderMin
      id
      attributes {
        orderId
        products {
          data {
            ...ProductOrderItem
          }
        }
        compositeProducts {
          data {
            id
            attributes {
              quantity
              purchaseType
              note
              itemId
              price
              status
              discounts {
                data {
                  ...DiscountMin
                }
              }
              compositeProduct {
                data {
                  ...CompositeProductLocationInfoWithQuantity
                }
              }
              order {
                data {
                  ...OrderMin
                }
              }
              tax {
                data {
                  ...TaxMin
                }
              }
              __typename
              discountAmountPerItem
            }
          }
        }
        services {
          data {
            ...ServiceOrderItem
          }
        }
        classes {
          data {
            ...ClassOrderItem
          }
        }
        memberships {
          data {
            ...MembershipOrderItem
          }
        }
        contact {
          data {
            ...Contact
          }
        }
        company {
          data {
            ...Company
          }
        }
        sales {
          data {
            ...User
          }
        }
        businessLocation {
          data {
            ...BusinessLocation
          }
        }
        discounts {
          data {
            ...DiscountMin
          }
        }
        files {
          data {
            ...File
          }
        }
        dealTransactions {
          data {
            ...DealTransactionMin
          }
        }
        invoice {
          data {
            ...InvoiceMin
          }
        }
      }
    }
  }
}
    ${OrderMinFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductLocationInfoWithQuantityFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceMinFragmentDoc}`;
export function useOrderByIdWithQuantitiesQuery(baseOptions: Apollo.QueryHookOptions<OrderByIdWithQuantitiesQuery, OrderByIdWithQuantitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderByIdWithQuantitiesQuery, OrderByIdWithQuantitiesQueryVariables>(OrderByIdWithQuantitiesDocument, options);
      }
export function useOrderByIdWithQuantitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderByIdWithQuantitiesQuery, OrderByIdWithQuantitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderByIdWithQuantitiesQuery, OrderByIdWithQuantitiesQueryVariables>(OrderByIdWithQuantitiesDocument, options);
        }
export type OrderByIdWithQuantitiesQueryHookResult = ReturnType<typeof useOrderByIdWithQuantitiesQuery>;
export type OrderByIdWithQuantitiesLazyQueryHookResult = ReturnType<typeof useOrderByIdWithQuantitiesLazyQuery>;
export type OrderByIdWithQuantitiesQueryResult = Apollo.QueryResult<OrderByIdWithQuantitiesQuery, OrderByIdWithQuantitiesQueryVariables>;
export const OrderItemsDocument = gql`
    query orderItems($orderId: String!) {
  orders(filters: {orderId: {eq: $orderId}}) {
    data {
      ...OrderWithItems
    }
  }
}
    ${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${OrderMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}`;
export function useOrderItemsQuery(baseOptions: Apollo.QueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
      }
export function useOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
        }
export type OrderItemsQueryHookResult = ReturnType<typeof useOrderItemsQuery>;
export type OrderItemsLazyQueryHookResult = ReturnType<typeof useOrderItemsLazyQuery>;
export type OrderItemsQueryResult = Apollo.QueryResult<OrderItemsQuery, OrderItemsQueryVariables>;
export const OrdersDocument = gql`
    query orders($filters: OrderFiltersInput!, $pagination: PaginationArg! = {}, $sort: [String] = []) {
  orders(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      ...Order
    }
    meta {
      ...Meta
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrdersHistoryDocument = gql`
    query ordersHistory($filters: OrderFiltersInput!, $pagination: PaginationArg! = {}, $sort: [String] = []) {
  orders(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      ...OrderMin
      ...OrderWithItems
      id
      attributes {
        contact {
          data {
            id
            attributes {
              uuid
              email
              fullName
              phoneNumber
              address
            }
          }
        }
        company {
          data {
            id
            attributes {
              name
              uuid
              website
              address
              email
              phoneNumber
              createdAt
            }
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useOrdersHistoryQuery(baseOptions: Apollo.QueryHookOptions<OrdersHistoryQuery, OrdersHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersHistoryQuery, OrdersHistoryQueryVariables>(OrdersHistoryDocument, options);
      }
export function useOrdersHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersHistoryQuery, OrdersHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersHistoryQuery, OrdersHistoryQueryVariables>(OrdersHistoryDocument, options);
        }
export type OrdersHistoryQueryHookResult = ReturnType<typeof useOrdersHistoryQuery>;
export type OrdersHistoryLazyQueryHookResult = ReturnType<typeof useOrdersHistoryLazyQuery>;
export type OrdersHistoryQueryResult = Apollo.QueryResult<OrdersHistoryQuery, OrdersHistoryQueryVariables>;
export const PlatformDocument = gql`
    query platform {
  platform {
    data {
      attributes {
        name
        logo {
          data {
            ...File
          }
        }
        minifiedLogo {
          data {
            ...File
          }
        }
        address
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function usePlatformQuery(baseOptions?: Apollo.QueryHookOptions<PlatformQuery, PlatformQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformQuery, PlatformQueryVariables>(PlatformDocument, options);
      }
export function usePlatformLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformQuery, PlatformQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformQuery, PlatformQueryVariables>(PlatformDocument, options);
        }
export type PlatformQueryHookResult = ReturnType<typeof usePlatformQuery>;
export type PlatformLazyQueryHookResult = ReturnType<typeof usePlatformLazyQuery>;
export type PlatformQueryResult = Apollo.QueryResult<PlatformQuery, PlatformQueryVariables>;
export const CreateProductInventoryItemEventDocument = gql`
    mutation createProductInventoryItemEvent($input: ProductInventoryItemEventInput!) {
  createProductInventoryItemEvent(data: $input) {
    data {
      ...ProductInventoryItemEvent
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${LeadMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type CreateProductInventoryItemEventMutationFn = Apollo.MutationFunction<CreateProductInventoryItemEventMutation, CreateProductInventoryItemEventMutationVariables>;
export function useCreateProductInventoryItemEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductInventoryItemEventMutation, CreateProductInventoryItemEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductInventoryItemEventMutation, CreateProductInventoryItemEventMutationVariables>(CreateProductInventoryItemEventDocument, options);
      }
export type CreateProductInventoryItemEventMutationHookResult = ReturnType<typeof useCreateProductInventoryItemEventMutation>;
export type CreateProductInventoryItemEventMutationResult = Apollo.MutationResult<CreateProductInventoryItemEventMutation>;
export const CreateProductsFromCsvDocument = gql`
    mutation createProductsFromCsv($input: CreateFromCSVInput!) {
  createProductsFromCsv(input: $input)
}
    `;
export type CreateProductsFromCsvMutationFn = Apollo.MutationFunction<CreateProductsFromCsvMutation, CreateProductsFromCsvMutationVariables>;
export function useCreateProductsFromCsvMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductsFromCsvMutation, CreateProductsFromCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductsFromCsvMutation, CreateProductsFromCsvMutationVariables>(CreateProductsFromCsvDocument, options);
      }
export type CreateProductsFromCsvMutationHookResult = ReturnType<typeof useCreateProductsFromCsvMutation>;
export type CreateProductsFromCsvMutationResult = Apollo.MutationResult<CreateProductsFromCsvMutation>;
export const DeleteProductInventoryItemEventDocument = gql`
    mutation deleteProductInventoryItemEvent($id: ID!) {
  deleteProductInventoryItemEvent(id: $id) {
    data {
      ...ProductInventoryItemEvent
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${LeadMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type DeleteProductInventoryItemEventMutationFn = Apollo.MutationFunction<DeleteProductInventoryItemEventMutation, DeleteProductInventoryItemEventMutationVariables>;
export function useDeleteProductInventoryItemEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductInventoryItemEventMutation, DeleteProductInventoryItemEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductInventoryItemEventMutation, DeleteProductInventoryItemEventMutationVariables>(DeleteProductInventoryItemEventDocument, options);
      }
export type DeleteProductInventoryItemEventMutationHookResult = ReturnType<typeof useDeleteProductInventoryItemEventMutation>;
export type DeleteProductInventoryItemEventMutationResult = Apollo.MutationResult<DeleteProductInventoryItemEventMutation>;
export const DeleteRentableDataDocument = gql`
    mutation deleteRentableData($id: ID!) {
  deleteRentableData(id: $id) {
    data {
      ...RentableData
    }
  }
}
    ${RentableDataFragmentDoc}`;
export type DeleteRentableDataMutationFn = Apollo.MutationFunction<DeleteRentableDataMutation, DeleteRentableDataMutationVariables>;
export function useDeleteRentableDataMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRentableDataMutation, DeleteRentableDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRentableDataMutation, DeleteRentableDataMutationVariables>(DeleteRentableDataDocument, options);
      }
export type DeleteRentableDataMutationHookResult = ReturnType<typeof useDeleteRentableDataMutation>;
export type DeleteRentableDataMutationResult = Apollo.MutationResult<DeleteRentableDataMutation>;
export const UpdateProductInventoryItemEventDocument = gql`
    mutation updateProductInventoryItemEvent($id: ID!, $input: ProductInventoryItemEventInput!) {
  updateProductInventoryItemEvent(id: $id, data: $input) {
    data {
      ...ProductInventoryItemEvent
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${LeadMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type UpdateProductInventoryItemEventMutationFn = Apollo.MutationFunction<UpdateProductInventoryItemEventMutation, UpdateProductInventoryItemEventMutationVariables>;
export function useUpdateProductInventoryItemEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductInventoryItemEventMutation, UpdateProductInventoryItemEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductInventoryItemEventMutation, UpdateProductInventoryItemEventMutationVariables>(UpdateProductInventoryItemEventDocument, options);
      }
export type UpdateProductInventoryItemEventMutationHookResult = ReturnType<typeof useUpdateProductInventoryItemEventMutation>;
export type UpdateProductInventoryItemEventMutationResult = Apollo.MutationResult<UpdateProductInventoryItemEventMutation>;
export const ProductBrandsDocument = gql`
    query productBrands($filters: ProductBrandFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  productBrands(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductBrand
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductBrandFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductBrandsQuery(baseOptions?: Apollo.QueryHookOptions<ProductBrandsQuery, ProductBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBrandsQuery, ProductBrandsQueryVariables>(ProductBrandsDocument, options);
      }
export function useProductBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBrandsQuery, ProductBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBrandsQuery, ProductBrandsQueryVariables>(ProductBrandsDocument, options);
        }
export type ProductBrandsQueryHookResult = ReturnType<typeof useProductBrandsQuery>;
export type ProductBrandsLazyQueryHookResult = ReturnType<typeof useProductBrandsLazyQuery>;
export type ProductBrandsQueryResult = Apollo.QueryResult<ProductBrandsQuery, ProductBrandsQueryVariables>;
export const ProductByUuidDocument = gql`
    query productByUuid($uuid: String!) {
  products(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export function useProductByUuidQuery(baseOptions: Apollo.QueryHookOptions<ProductByUuidQuery, ProductByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductByUuidQuery, ProductByUuidQueryVariables>(ProductByUuidDocument, options);
      }
export function useProductByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductByUuidQuery, ProductByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductByUuidQuery, ProductByUuidQueryVariables>(ProductByUuidDocument, options);
        }
export type ProductByUuidQueryHookResult = ReturnType<typeof useProductByUuidQuery>;
export type ProductByUuidLazyQueryHookResult = ReturnType<typeof useProductByUuidLazyQuery>;
export type ProductByUuidQueryResult = Apollo.QueryResult<ProductByUuidQuery, ProductByUuidQueryVariables>;
export const ProductInventoryByUuidDocument = gql`
    query productInventoryByUuid($uuid: String!, $businessLocationID: ID) {
  productInventoryItems(
    filters: {uuid: {eq: $uuid}, businessLocation: {id: {eq: $businessLocationID}}}
  ) {
    data {
      ...ProductInventoryItem
    }
  }
}
    ${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}`;
export function useProductInventoryByUuidQuery(baseOptions: Apollo.QueryHookOptions<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>(ProductInventoryByUuidDocument, options);
      }
export function useProductInventoryByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>(ProductInventoryByUuidDocument, options);
        }
export type ProductInventoryByUuidQueryHookResult = ReturnType<typeof useProductInventoryByUuidQuery>;
export type ProductInventoryByUuidLazyQueryHookResult = ReturnType<typeof useProductInventoryByUuidLazyQuery>;
export type ProductInventoryByUuidQueryResult = Apollo.QueryResult<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>;
export const ProductInventoryItemEventsByIdDocument = gql`
    query productInventoryItemEventsById($id: ID!, $pagination: PaginationArg, $sort: [String] = [], $eventType: String) {
  productInventoryItemEvents(
    filters: {productInventoryItem: {id: {eq: $id}}, eventType: {eq: $eventType}}
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...ProductInventoryItemEvent
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${LeadMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductInventoryItemEventsByIdQuery(baseOptions: Apollo.QueryHookOptions<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>(ProductInventoryItemEventsByIdDocument, options);
      }
export function useProductInventoryItemEventsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>(ProductInventoryItemEventsByIdDocument, options);
        }
export type ProductInventoryItemEventsByIdQueryHookResult = ReturnType<typeof useProductInventoryItemEventsByIdQuery>;
export type ProductInventoryItemEventsByIdLazyQueryHookResult = ReturnType<typeof useProductInventoryItemEventsByIdLazyQuery>;
export type ProductInventoryItemEventsByIdQueryResult = Apollo.QueryResult<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>;
export const ProductInventoryItemsDocument = gql`
    query productInventoryItems($filters: ProductInventoryItemFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  productInventoryItems(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductInventoryItem
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductInventoryItemFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductInventoryItemsQuery(baseOptions?: Apollo.QueryHookOptions<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>(ProductInventoryItemsDocument, options);
      }
export function useProductInventoryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>(ProductInventoryItemsDocument, options);
        }
export type ProductInventoryItemsQueryHookResult = ReturnType<typeof useProductInventoryItemsQuery>;
export type ProductInventoryItemsLazyQueryHookResult = ReturnType<typeof useProductInventoryItemsLazyQuery>;
export type ProductInventoryItemsQueryResult = Apollo.QueryResult<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>;
export const ProductTypesDocument = gql`
    query productTypes($filters: ProductTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  productTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductType
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<ProductTypesQuery, ProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypesQuery, ProductTypesQueryVariables>(ProductTypesDocument, options);
      }
export function useProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypesQuery, ProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypesQuery, ProductTypesQueryVariables>(ProductTypesDocument, options);
        }
export type ProductTypesQueryHookResult = ReturnType<typeof useProductTypesQuery>;
export type ProductTypesLazyQueryHookResult = ReturnType<typeof useProductTypesLazyQuery>;
export type ProductTypesQueryResult = Apollo.QueryResult<ProductTypesQuery, ProductTypesQueryVariables>;
export const ProductsDocument = gql`
    query products($filters: ProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  products(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Product
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductsInventoryItemPriceRangeDocument = gql`
    query productsInventoryItemPriceRange($id: ID!) {
  productsInventoryItemPriceRange(id: $id) {
    minPrice
    maxPrice
  }
}
    `;
export function useProductsInventoryItemPriceRangeQuery(baseOptions: Apollo.QueryHookOptions<ProductsInventoryItemPriceRangeQuery, ProductsInventoryItemPriceRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsInventoryItemPriceRangeQuery, ProductsInventoryItemPriceRangeQueryVariables>(ProductsInventoryItemPriceRangeDocument, options);
      }
export function useProductsInventoryItemPriceRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsInventoryItemPriceRangeQuery, ProductsInventoryItemPriceRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsInventoryItemPriceRangeQuery, ProductsInventoryItemPriceRangeQueryVariables>(ProductsInventoryItemPriceRangeDocument, options);
        }
export type ProductsInventoryItemPriceRangeQueryHookResult = ReturnType<typeof useProductsInventoryItemPriceRangeQuery>;
export type ProductsInventoryItemPriceRangeLazyQueryHookResult = ReturnType<typeof useProductsInventoryItemPriceRangeLazyQuery>;
export type ProductsInventoryItemPriceRangeQueryResult = Apollo.QueryResult<ProductsInventoryItemPriceRangeQuery, ProductsInventoryItemPriceRangeQueryVariables>;
export const ProductsPriceRangeDocument = gql`
    query productsPriceRange {
  productsPriceRange {
    minPrice
    maxPrice
  }
}
    `;
export function useProductsPriceRangeQuery(baseOptions?: Apollo.QueryHookOptions<ProductsPriceRangeQuery, ProductsPriceRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsPriceRangeQuery, ProductsPriceRangeQueryVariables>(ProductsPriceRangeDocument, options);
      }
export function useProductsPriceRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsPriceRangeQuery, ProductsPriceRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsPriceRangeQuery, ProductsPriceRangeQueryVariables>(ProductsPriceRangeDocument, options);
        }
export type ProductsPriceRangeQueryHookResult = ReturnType<typeof useProductsPriceRangeQuery>;
export type ProductsPriceRangeLazyQueryHookResult = ReturnType<typeof useProductsPriceRangeLazyQuery>;
export type ProductsPriceRangeQueryResult = Apollo.QueryResult<ProductsPriceRangeQuery, ProductsPriceRangeQueryVariables>;
export const QuestionDocument = gql`
    query question($id: ID!) {
  question(id: $id) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionMinFragmentDoc}`;
export function useQuestionQuery(baseOptions: Apollo.QueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
      }
export function useQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
        }
export type QuestionQueryHookResult = ReturnType<typeof useQuestionQuery>;
export type QuestionLazyQueryHookResult = ReturnType<typeof useQuestionLazyQuery>;
export type QuestionQueryResult = Apollo.QueryResult<QuestionQuery, QuestionQueryVariables>;
export const QuestionsDocument = gql`
    query questions($filters: QuestionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  questions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Question
    }
    meta {
      ...Meta
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const RolesDocument = gql`
    query roles($filters: UsersPermissionsRoleFiltersInput!) {
  usersPermissionsRoles(filters: $filters) {
    data {
      id
      attributes {
        name
        type
      }
    }
  }
}
    `;
export function useRolesQuery(baseOptions: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const ServiceByUuidDocument = gql`
    query serviceByUuid($uuid: String!) {
  services(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Service
    }
  }
}
    ${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}`;
export function useServiceByUuidQuery(baseOptions: Apollo.QueryHookOptions<ServiceByUuidQuery, ServiceByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceByUuidQuery, ServiceByUuidQueryVariables>(ServiceByUuidDocument, options);
      }
export function useServiceByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceByUuidQuery, ServiceByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceByUuidQuery, ServiceByUuidQueryVariables>(ServiceByUuidDocument, options);
        }
export type ServiceByUuidQueryHookResult = ReturnType<typeof useServiceByUuidQuery>;
export type ServiceByUuidLazyQueryHookResult = ReturnType<typeof useServiceByUuidLazyQuery>;
export type ServiceByUuidQueryResult = Apollo.QueryResult<ServiceByUuidQuery, ServiceByUuidQueryVariables>;
export const ServicePerformersDocument = gql`
    query servicePerformers($filters: ServicePerformerFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  servicePerformers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ServicePerformer
    }
    meta {
      ...Meta
    }
  }
}
    ${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useServicePerformersQuery(baseOptions?: Apollo.QueryHookOptions<ServicePerformersQuery, ServicePerformersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicePerformersQuery, ServicePerformersQueryVariables>(ServicePerformersDocument, options);
      }
export function useServicePerformersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicePerformersQuery, ServicePerformersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicePerformersQuery, ServicePerformersQueryVariables>(ServicePerformersDocument, options);
        }
export type ServicePerformersQueryHookResult = ReturnType<typeof useServicePerformersQuery>;
export type ServicePerformersLazyQueryHookResult = ReturnType<typeof useServicePerformersLazyQuery>;
export type ServicePerformersQueryResult = Apollo.QueryResult<ServicePerformersQuery, ServicePerformersQueryVariables>;
export const ServicesDocument = gql`
    query services($filters: ServiceFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  services(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Service
    }
    meta {
      ...Meta
    }
  }
}
    ${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${SubLocationMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const ServicesPriceRangeDocument = gql`
    query servicesPriceRange($id: ID!) {
  servicesPriceRange(id: $id) {
    minPrice
    maxPrice
  }
}
    `;
export function useServicesPriceRangeQuery(baseOptions: Apollo.QueryHookOptions<ServicesPriceRangeQuery, ServicesPriceRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesPriceRangeQuery, ServicesPriceRangeQueryVariables>(ServicesPriceRangeDocument, options);
      }
export function useServicesPriceRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesPriceRangeQuery, ServicesPriceRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesPriceRangeQuery, ServicesPriceRangeQueryVariables>(ServicesPriceRangeDocument, options);
        }
export type ServicesPriceRangeQueryHookResult = ReturnType<typeof useServicesPriceRangeQuery>;
export type ServicesPriceRangeLazyQueryHookResult = ReturnType<typeof useServicesPriceRangeLazyQuery>;
export type ServicesPriceRangeQueryResult = Apollo.QueryResult<ServicesPriceRangeQuery, ServicesPriceRangeQueryVariables>;
export const CreateShipmentCardDocument = gql`
    mutation createShipmentCard($input: ShipmentCardInput!) {
  createShipmentCard(data: $input) {
    data {
      ...ShipmentCard
    }
  }
}
    ${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export type CreateShipmentCardMutationFn = Apollo.MutationFunction<CreateShipmentCardMutation, CreateShipmentCardMutationVariables>;
export function useCreateShipmentCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateShipmentCardMutation, CreateShipmentCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShipmentCardMutation, CreateShipmentCardMutationVariables>(CreateShipmentCardDocument, options);
      }
export type CreateShipmentCardMutationHookResult = ReturnType<typeof useCreateShipmentCardMutation>;
export type CreateShipmentCardMutationResult = Apollo.MutationResult<CreateShipmentCardMutation>;
export const DeleteShipmentCardDocument = gql`
    mutation deleteShipmentCard($id: ID!) {
  deleteShipmentCard(id: $id) {
    data {
      ...ShipmentCard
    }
  }
}
    ${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export type DeleteShipmentCardMutationFn = Apollo.MutationFunction<DeleteShipmentCardMutation, DeleteShipmentCardMutationVariables>;
export function useDeleteShipmentCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShipmentCardMutation, DeleteShipmentCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShipmentCardMutation, DeleteShipmentCardMutationVariables>(DeleteShipmentCardDocument, options);
      }
export type DeleteShipmentCardMutationHookResult = ReturnType<typeof useDeleteShipmentCardMutation>;
export type DeleteShipmentCardMutationResult = Apollo.MutationResult<DeleteShipmentCardMutation>;
export const UpdateShipmentCardDocument = gql`
    mutation updateShipmentCard($id: ID!, $input: ShipmentCardInput!) {
  updateShipmentCard(id: $id, data: $input) {
    data {
      ...ShipmentCard
    }
  }
}
    ${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export type UpdateShipmentCardMutationFn = Apollo.MutationFunction<UpdateShipmentCardMutation, UpdateShipmentCardMutationVariables>;
export function useUpdateShipmentCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShipmentCardMutation, UpdateShipmentCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShipmentCardMutation, UpdateShipmentCardMutationVariables>(UpdateShipmentCardDocument, options);
      }
export type UpdateShipmentCardMutationHookResult = ReturnType<typeof useUpdateShipmentCardMutation>;
export type UpdateShipmentCardMutationResult = Apollo.MutationResult<UpdateShipmentCardMutation>;
export const ShipmentCardDocument = gql`
    query shipmentCard($id: ID!) {
  shipmentCard(id: $id) {
    data {
      ...ShipmentCard
    }
  }
}
    ${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export function useShipmentCardQuery(baseOptions: Apollo.QueryHookOptions<ShipmentCardQuery, ShipmentCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentCardQuery, ShipmentCardQueryVariables>(ShipmentCardDocument, options);
      }
export function useShipmentCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentCardQuery, ShipmentCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentCardQuery, ShipmentCardQueryVariables>(ShipmentCardDocument, options);
        }
export type ShipmentCardQueryHookResult = ReturnType<typeof useShipmentCardQuery>;
export type ShipmentCardLazyQueryHookResult = ReturnType<typeof useShipmentCardLazyQuery>;
export type ShipmentCardQueryResult = Apollo.QueryResult<ShipmentCardQuery, ShipmentCardQueryVariables>;
export const ShipmentCardsDocument = gql`
    query shipmentCards($filters: ShipmentCardFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  shipmentCards(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ShipmentCard
    }
    meta {
      ...Meta
    }
  }
}
    ${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useShipmentCardsQuery(baseOptions?: Apollo.QueryHookOptions<ShipmentCardsQuery, ShipmentCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentCardsQuery, ShipmentCardsQueryVariables>(ShipmentCardsDocument, options);
      }
export function useShipmentCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentCardsQuery, ShipmentCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentCardsQuery, ShipmentCardsQueryVariables>(ShipmentCardsDocument, options);
        }
export type ShipmentCardsQueryHookResult = ReturnType<typeof useShipmentCardsQuery>;
export type ShipmentCardsLazyQueryHookResult = ReturnType<typeof useShipmentCardsLazyQuery>;
export type ShipmentCardsQueryResult = Apollo.QueryResult<ShipmentCardsQuery, ShipmentCardsQueryVariables>;
export const DeleteTaskAndFollowingActivityDocument = gql`
    mutation deleteTaskAndFollowingActivity($input: DeleteTaskAndFollowingActivityInput!) {
  deleteTaskAndFollowingActivity(input: $input)
}
    `;
export type DeleteTaskAndFollowingActivityMutationFn = Apollo.MutationFunction<DeleteTaskAndFollowingActivityMutation, DeleteTaskAndFollowingActivityMutationVariables>;
export function useDeleteTaskAndFollowingActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskAndFollowingActivityMutation, DeleteTaskAndFollowingActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskAndFollowingActivityMutation, DeleteTaskAndFollowingActivityMutationVariables>(DeleteTaskAndFollowingActivityDocument, options);
      }
export type DeleteTaskAndFollowingActivityMutationHookResult = ReturnType<typeof useDeleteTaskAndFollowingActivityMutation>;
export type DeleteTaskAndFollowingActivityMutationResult = Apollo.MutationResult<DeleteTaskAndFollowingActivityMutation>;
export const TaxesDocument = gql`
    query taxes($filters: TaxFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  taxes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Tax
    }
    meta {
      ...Meta
    }
  }
}
    ${TaxFragmentDoc}
${TaxMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTaxesQuery(baseOptions?: Apollo.QueryHookOptions<TaxesQuery, TaxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxesQuery, TaxesQueryVariables>(TaxesDocument, options);
      }
export function useTaxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxesQuery, TaxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxesQuery, TaxesQueryVariables>(TaxesDocument, options);
        }
export type TaxesQueryHookResult = ReturnType<typeof useTaxesQuery>;
export type TaxesLazyQueryHookResult = ReturnType<typeof useTaxesLazyQuery>;
export type TaxesQueryResult = Apollo.QueryResult<TaxesQuery, TaxesQueryVariables>;
export const TenantBySlugDocument = gql`
    query tenantBySlug($tenantSlug: String!) {
  tenants(filters: {slug: {eq: $tenantSlug}}) {
    data {
      ...Tenant
    }
  }
}
    ${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}`;
export function useTenantBySlugQuery(baseOptions: Apollo.QueryHookOptions<TenantBySlugQuery, TenantBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantBySlugQuery, TenantBySlugQueryVariables>(TenantBySlugDocument, options);
      }
export function useTenantBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantBySlugQuery, TenantBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantBySlugQuery, TenantBySlugQueryVariables>(TenantBySlugDocument, options);
        }
export type TenantBySlugQueryHookResult = ReturnType<typeof useTenantBySlugQuery>;
export type TenantBySlugLazyQueryHookResult = ReturnType<typeof useTenantBySlugLazyQuery>;
export type TenantBySlugQueryResult = Apollo.QueryResult<TenantBySlugQuery, TenantBySlugQueryVariables>;
export const ResendConfirmationDocument = gql`
    mutation resendConfirmation($input: ResendConfirmationInput!) {
  resendConfirmation(input: $input)
}
    `;
export type ResendConfirmationMutationFn = Apollo.MutationFunction<ResendConfirmationMutation, ResendConfirmationMutationVariables>;
export function useResendConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationMutation, ResendConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationMutation, ResendConfirmationMutationVariables>(ResendConfirmationDocument, options);
      }
export type ResendConfirmationMutationHookResult = ReturnType<typeof useResendConfirmationMutation>;
export type ResendConfirmationMutationResult = Apollo.MutationResult<ResendConfirmationMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${LeadMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ShipmentCardFragmentDoc}
${ShipmentCardMinFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const CreateWebsiteDocument = gql`
    mutation createWebsite($input: WebsiteInput!) {
  createWebsite(data: $input) {
    data {
      ...Website
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}`;
export type CreateWebsiteMutationFn = Apollo.MutationFunction<CreateWebsiteMutation, CreateWebsiteMutationVariables>;
export function useCreateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(CreateWebsiteDocument, options);
      }
export type CreateWebsiteMutationHookResult = ReturnType<typeof useCreateWebsiteMutation>;
export type CreateWebsiteMutationResult = Apollo.MutationResult<CreateWebsiteMutation>;
export const UpdateWebsiteDocument = gql`
    mutation updateWebsite($id: ID!, $input: WebsiteInput!) {
  updateWebsite(id: $id, data: $input) {
    data {
      ...Website
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}`;
export type UpdateWebsiteMutationFn = Apollo.MutationFunction<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;
export function useUpdateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>(UpdateWebsiteDocument, options);
      }
export type UpdateWebsiteMutationHookResult = ReturnType<typeof useUpdateWebsiteMutation>;
export type UpdateWebsiteMutationResult = Apollo.MutationResult<UpdateWebsiteMutation>;
export const WebsiteIdsDocument = gql`
    query websiteIds($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useWebsiteIdsQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteIdsQuery, WebsiteIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIdsQuery, WebsiteIdsQueryVariables>(WebsiteIdsDocument, options);
      }
export function useWebsiteIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIdsQuery, WebsiteIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIdsQuery, WebsiteIdsQueryVariables>(WebsiteIdsDocument, options);
        }
export type WebsiteIdsQueryHookResult = ReturnType<typeof useWebsiteIdsQuery>;
export type WebsiteIdsLazyQueryHookResult = ReturnType<typeof useWebsiteIdsLazyQuery>;
export type WebsiteIdsQueryResult = Apollo.QueryResult<WebsiteIdsQuery, WebsiteIdsQueryVariables>;
export const WebsiteDocument = gql`
    query website($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Website
    }
    meta {
      ...Meta
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useWebsiteQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
      }
export function useWebsiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
        }
export type WebsiteQueryHookResult = ReturnType<typeof useWebsiteQuery>;
export type WebsiteLazyQueryHookResult = ReturnType<typeof useWebsiteLazyQuery>;
export type WebsiteQueryResult = Apollo.QueryResult<WebsiteQuery, WebsiteQueryVariables>;
export const WebsiteByIdDocument = gql`
    query websiteById($id: ID!) {
  website(id: $id) {
    data {
      ...Website
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}`;
export function useWebsiteByIdQuery(baseOptions: Apollo.QueryHookOptions<WebsiteByIdQuery, WebsiteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteByIdQuery, WebsiteByIdQueryVariables>(WebsiteByIdDocument, options);
      }
export function useWebsiteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteByIdQuery, WebsiteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteByIdQuery, WebsiteByIdQueryVariables>(WebsiteByIdDocument, options);
        }
export type WebsiteByIdQueryHookResult = ReturnType<typeof useWebsiteByIdQuery>;
export type WebsiteByIdLazyQueryHookResult = ReturnType<typeof useWebsiteByIdLazyQuery>;
export type WebsiteByIdQueryResult = Apollo.QueryResult<WebsiteByIdQuery, WebsiteByIdQueryVariables>;
export const WebsiteContactsDocument = gql`
    query websiteContacts($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        subdomain
        websiteContacts {
          ...WebsiteContacts
        }
      }
    }
  }
}
    ${WebsiteContactsFragmentDoc}`;
export function useWebsiteContactsQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteContactsQuery, WebsiteContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteContactsQuery, WebsiteContactsQueryVariables>(WebsiteContactsDocument, options);
      }
export function useWebsiteContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteContactsQuery, WebsiteContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteContactsQuery, WebsiteContactsQueryVariables>(WebsiteContactsDocument, options);
        }
export type WebsiteContactsQueryHookResult = ReturnType<typeof useWebsiteContactsQuery>;
export type WebsiteContactsLazyQueryHookResult = ReturnType<typeof useWebsiteContactsLazyQuery>;
export type WebsiteContactsQueryResult = Apollo.QueryResult<WebsiteContactsQuery, WebsiteContactsQueryVariables>;
export const WebsiteShipmentDocument = gql`
    query websiteShipment($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        shipmentCost {
          ...ShipmentCost
        }
      }
    }
  }
}
    ${ShipmentCostFragmentDoc}`;
export function useWebsiteShipmentQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteShipmentQuery, WebsiteShipmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteShipmentQuery, WebsiteShipmentQueryVariables>(WebsiteShipmentDocument, options);
      }
export function useWebsiteShipmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteShipmentQuery, WebsiteShipmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteShipmentQuery, WebsiteShipmentQueryVariables>(WebsiteShipmentDocument, options);
        }
export type WebsiteShipmentQueryHookResult = ReturnType<typeof useWebsiteShipmentQuery>;
export type WebsiteShipmentLazyQueryHookResult = ReturnType<typeof useWebsiteShipmentLazyQuery>;
export type WebsiteShipmentQueryResult = Apollo.QueryResult<WebsiteShipmentQuery, WebsiteShipmentQueryVariables>;
export const WebsiteShopItemsTypeDocument = gql`
    query websiteShopItemsType($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        ProductTypesVisibility {
          ...ProductTypesVisibility
        }
      }
    }
  }
}
    ${ProductTypesVisibilityFragmentDoc}`;
export function useWebsiteShopItemsTypeQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteShopItemsTypeQuery, WebsiteShopItemsTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteShopItemsTypeQuery, WebsiteShopItemsTypeQueryVariables>(WebsiteShopItemsTypeDocument, options);
      }
export function useWebsiteShopItemsTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteShopItemsTypeQuery, WebsiteShopItemsTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteShopItemsTypeQuery, WebsiteShopItemsTypeQueryVariables>(WebsiteShopItemsTypeDocument, options);
        }
export type WebsiteShopItemsTypeQueryHookResult = ReturnType<typeof useWebsiteShopItemsTypeQuery>;
export type WebsiteShopItemsTypeLazyQueryHookResult = ReturnType<typeof useWebsiteShopItemsTypeLazyQuery>;
export type WebsiteShopItemsTypeQueryResult = Apollo.QueryResult<WebsiteShopItemsTypeQuery, WebsiteShopItemsTypeQueryVariables>;