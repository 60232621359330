import {
  OrderItemTypeMap,
  useDeleteOrderItem,
} from '@hooks/useDeleteOrderItem';
import { useCallback } from 'react';

type DeleteItemFromCartProps = {
  itemId: string;
  itemType: OrderItemTypeMap;
};
export function useDeleteItemFromCart({
  itemId,
  itemType,
}: DeleteItemFromCartProps) {
  const { deleteItem, loading: deleteLoading } = useDeleteOrderItem(itemType);

  const handleDelete = useCallback(async () => {
    await deleteItem({ variables: { id: itemId } });
  }, [deleteItem, itemId]);

  return {
    handleDelete,
    deleteLoading,
  };
}
