import { Icon } from '@assets/icon';
import { Loader } from '@components/layout/MainLayout';
import { formatToCurrency } from '@helpers/formatter';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useDeleteItemFromCart } from '@hooks/useDeleteItemFromCart';
import { useToken } from '@hooks/useToken';
import { useUpdateItemInCart } from '@hooks/useUpdateItemInCart';
import { CustomButton } from '@ui/button/Button';
import CustomInputNumber from '@ui/custom-number-input/CustomInputNumber';
import ItemImage from '@ui/item-image/ItemImage';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import styles from 'src/pages/website/cart/cart-drawer-item/CartDrawerItem.module.scss';

const { Text } = Typography;

type Props = {
  data: MembershipOrderItemEntity;
};
const CartDrawerMembership: FC<Props> = ({ data }) => {
  const { sm } = useBreakpoint();
  const { token } = useToken();

  const { handleUpdate } = useUpdateItemInCart({
    itemType: 'memberships',
    itemId: data.id as string,
    itemPrice: data.attributes?.price as number,
    itemTax: data.attributes?.tax?.data?.id as string,
  });

  const { handleDelete, deleteLoading } = useDeleteItemFromCart({
    itemType: 'memberships',
    itemId: data.id as string,
  });

  return (
    <>
      {sm ? (
        <div className={styles.drawerCartItem}>
          <ItemImage wrapperClass={styles.imageWrapper} />
          <Col span={14}>
            <Text strong>
              {data?.attributes?.membership?.data?.attributes?.name}
            </Text>
            <Row style={{ padding: '12px 0' }}>
              <Col span={14}>
                <CustomInputNumber
                  initValue={Number(data?.attributes?.quantity)}
                  minValue={1}
                  maxValue={10}
                  onChange={handleUpdate}
                />
              </Col>
            </Row>
            <Text
              className={styles.secondaryText}
            >{`${data?.attributes?.quantity} x `}</Text>
            <Text strong className={styles.secondaryText}>
              {formatToCurrency(data?.attributes?.price)}
            </Text>
          </Col>
          <Col span={3}>
            <CustomButton
              type={'text'}
              onClick={handleDelete}
              disabled={deleteLoading}
              style={{ color: token.colorError }}
              icon={deleteLoading ? <Loader /> : <Icon type={'bin'} />}
            />
          </Col>
        </div>
      ) : (
        <div className={styles.drawerCartItemMobile}>
          <div className={styles.topBlock}>
            <ItemImage wrapperClass={styles.imageWrapper} />
            <div className={styles.topInfoBlock}>
              <div>
                <Text strong>
                  {data?.attributes?.membership?.data?.attributes?.name}
                </Text>
              </div>
              <div>
                <Text
                  className={styles.secondaryText}
                >{`${data?.attributes?.quantity} x `}</Text>
                <Text strong className={styles.secondaryText}>
                  {formatToCurrency(data?.attributes?.price)}
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.bottomBlock}>
            <div className={styles.counter}>
              <CustomInputNumber
                initValue={Number(data?.attributes?.quantity)}
                minValue={1}
                maxValue={10}
                onChange={handleUpdate}
              />
            </div>
            <CustomButton
              type={'text'}
              onClick={handleDelete}
              disabled={deleteLoading}
              style={{ color: token.colorError }}
              icon={deleteLoading ? <Loader /> : <Icon type={'bin'} />}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CartDrawerMembership;
