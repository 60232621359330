import { customerWebsiteUrl } from '@helpers/external';

export const getWebsiteFilter = () => {
  const currentUrl = window.location.href;
  const withoutProtocol = currentUrl?.replace(/(^\w+:|^)\/\//, '');
  const subdomain = withoutProtocol?.split(`.${customerWebsiteUrl}`)[0];
  return {
    variables: {
      filters: {
        websiteType: {
          eq: subdomain.includes('draft') ? 'draft' : 'public',
        },
      },
    },
  };
};
