import { useWebsiteQuery } from '@/graphql';
import { getWebsiteFilter } from '@helpers/getWebsiteFilters';
import { CustomSpace } from '@ui/space';
import { Layout } from 'antd';
import { FC, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Footer, Header } from './index';
const { Content } = Layout;
const MainLayout: FC = () => {
  const { data, loading } = useWebsiteQuery(getWebsiteFilter());
  const website = data?.websites?.data?.[0] as WebsiteEntity;
  const websiteName = website?.attributes?.initialSettings?.heading?.title;

  const favicon = website?.attributes?.initialSettings?.image?.find(
    (it) => it?.title === 'favicon',
  ) as CardFragment;

  useEffect(() => {
    if (!loading) {
      document.title = websiteName || 'Website name';

      const faviconUrl = favicon?.media?.data?.attributes?.url;
      if (faviconUrl) {
        const faviconElement = document.getElementById('favicon');
        faviconElement?.setAttribute('href', faviconUrl);
      }
    }
  }, [loading, websiteName, favicon]);

  return (
    <CustomSpace direction={'vertical'} block size={[0, 48]}>
      <Layout>
        <Header data={website} loading={loading} />
        <Content style={{ minHeight: '100vh' }}>
          <Suspense>
            <Outlet />
          </Suspense>
        </Content>
        <Footer data={website} loading={loading} />
      </Layout>
    </CustomSpace>
  );
};

export { MainLayout };
