import React, { FC } from 'react';

import classNames from 'classnames';

import { Icon } from '@assets/icon';
import styles from './ImagePlaceholder.module.scss';

type Props = {
  placeholderClass?: string;
  placeholderStyle?: React.CSSProperties;
};
const ImagePlaceholder: FC<Props> = ({
  placeholderClass,
  placeholderStyle,
}) => {
  return (
    <div
      className={classNames(styles.imagePlaceholder, placeholderClass)}
      style={placeholderStyle}
    >
      <Icon type={'image-placeholder'} exactSize={64} />
    </div>
  );
};

export default ImagePlaceholder;
