import { BaseSyntheticEvent, FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Badge, Col, Divider, Drawer, Modal, Row, Typography } from 'antd';

import classNames from 'classnames';

import CustomContainer from '@components/layout/MainLayout/custom-container/CustomContainer';

import { customerWebsiteRoutes } from '@router/routes';

import { useBreakpoint } from '@hooks/useBreakpoint';

import { Icon } from '@assets/icon';

import { CustomButton } from '@ui/button/Button';

import { Logo } from '@/components/ui/logo';
import SearchForm from '@components/layout/MainLayout/header/SearchForm';
import HeaderMenu from '@ui/header-menu/HeaderMenu';
import HeaderUser from '@ui/header-user/HeaderUser';
import ShopSelector from '@ui/shop-selector/ShopSelector';
import { useForm } from 'antd/es/form/Form';

import { LocalStorageCart } from '@/types';
import useItemsQuantityInCart from '@hooks/itemQuantityInCart';
import { useLocalOrderExistenceCheck } from '@hooks/useLocalOrderExistenceCheck';
import useLocationLocalStorage from '@hooks/useLocationLocalStorage';
import { useStorageType } from '@hooks/useStorage';
import CartDrawer from '@pages/website/cart/cart-drawer/CartDrawer';
import { useLocalStorage } from 'usehooks-ts';
import styles from './Header.module.scss';
const { shop } = customerWebsiteRoutes;
const { Paragraph, Title } = Typography;

const Header: FC<{ data: WebsiteEntity; loading: boolean }> = ({
  data,
  loading,
}) => {
  const [form] = useForm();
  const navigate = useNavigate();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { md, lg } = useBreakpoint();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const count = useItemsQuantityInCart();
  const type = useStorageType();
  useLocalOrderExistenceCheck();

  const [localStorageLocation, setLocalStorageLocation] = useLocalStorage(
    'location',
    '',
  );
  const [currentLocation, setCurrentLocation] = useState(localStorageLocation);

  useEffect(() => {
    setCurrentLocation(localStorageLocation);
  }, [localStorageLocation, setLocalStorageLocation]);

  useLocationLocalStorage();

  const logo = data?.attributes?.initialSettings?.image?.find(
    (it) => it?.title === 'logo',
  ) as CardFragment;

  const handleToggleMobileMenu = () => {
    setShowMobileMenu((prevState) => !prevState);
  };
  const toggleShowDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };
  const onCartClick = () => {
    setIsDrawerOpen((prev) => !prev);
  };
  const onMobileCartClick = () => {
    setShowMobileMenu((prevState) => !prevState);
    setIsDrawerOpen((prev) => !prev);
  };
  const handleSearch = () => {
    setIsSearchActive(true);
    if (isSearchActive) {
      setIsSearchActive(false);
      if (isReadyToSubmit) {
        form.submit();
      }
    }
  };
  const handleFormChange = (e: BaseSyntheticEvent) => {
    if (e.target.value.length > 1) {
      setIsReadyToSubmit(true);
    } else {
      setIsReadyToSubmit(false);
    }
  };
  const handleFinish = (value: { searchText: string }) => {
    setShowMobileMenu(false);
    if (value?.searchText) {
      navigate(
        `${shop}/${currentLocation}/products/?page=1&name=${value?.searchText.trim()}`,
      );
    }
    setIsSearchActive(false);
    form.resetFields();
  };

  const searchButtonStyles = classNames(styles.searchButton, {
    [styles.disabled]: isReadyToSubmit,
  });

  const [, setLocalStorageCart] = useLocalStorage(
    'cart',
    [] as LocalStorageCart,
  );
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState('');

  const showModal = (location: string) => {
    setSelectedLocation(location);
    setOpen(true);
  };
  const handleOk = async () => {
    setCurrentLocation(selectedLocation);
    setLocalStorageLocation(selectedLocation);
    setOpen(false);

    setLocalStorageCart([]);
    localStorage.removeItem('order');

    const isShopPage = location.pathname.includes('shop');
    const isCheckoutPage = location.pathname.includes('checkout');

    if (isShopPage || isCheckoutPage) {
      navigate(`${shop}/${selectedLocation}/${type}`);
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <header className={styles.header}>
      <CustomContainer>
        <Row align={'middle'} justify={'space-between'}>
          {lg ? (
            <></>
          ) : (
            <Col>
              <CustomButton
                type={'text'}
                onClick={handleToggleMobileMenu}
                className={styles.burgerButton}
              >
                <Icon type={'burger-menu'} />
              </CustomButton>

              <Drawer
                placement={'left'}
                closable={false}
                onClose={handleToggleMobileMenu}
                open={showMobileMenu}
                key={'left'}
                destroyOnClose={true}
              >
                <Row
                  align={'middle'}
                  justify={'space-between'}
                  className={styles.menuMobileHeader}
                >
                  <Col>
                    <CustomButton
                      type={'text'}
                      onClick={handleToggleMobileMenu}
                    >
                      <Icon type={'close'} />
                    </CustomButton>
                  </Col>
                  <Col flex={'auto'}>
                    <Row
                      justify={'end'}
                      style={{ minWidth: 80 }}
                      align={'middle'}
                    >
                      {isSearchActive ? (
                        <SearchForm
                          handleFinish={(value) => handleFinish(value)}
                          handleFormChange={(e) => handleFormChange(e)}
                          form={form}
                        />
                      ) : null}
                      <CustomButton type={'text'} onClick={handleSearch}>
                        <Icon type={'mag-glass'} />
                      </CustomButton>
                      <Badge
                        count={count}
                        size={'small'}
                        offset={[-5, 5]}
                        color={'#2b2d45'}
                      >
                        <CustomButton
                          type={'text'}
                          onClick={onMobileCartClick}
                          disabled={count === 0}
                        >
                          <Icon type={'cart'} />
                        </CustomButton>
                      </Badge>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ margin: '14px 0 0 -4px' }}>
                  <Col flex={'auto'}>
                    <Row justify={'start'}>
                      <ShopSelector
                        showModal={showModal}
                        onCloseDrawer={handleToggleMobileMenu}
                      />
                    </Row>
                  </Col>
                </Row>
                <Divider style={{ margin: '8px 0' }} />
                <Row style={{ margin: '14px 0 0 -4px' }}>
                  <Col flex={'auto'}>
                    <Row justify={'start'}>
                      <HeaderMenu
                        menuMode={'inline'}
                        handleToggleMobileMenu={handleToggleMobileMenu}
                      />
                    </Row>
                  </Col>
                </Row>
              </Drawer>
            </Col>
          )}

          <Col span={2} style={{ minWidth: 'fit-content' }}>
            <Row align={'middle'} gutter={16}>
              <Col>
                <Logo
                  url={
                    logo?.media?.data?.attributes?.url
                      ? logo?.media?.data?.attributes?.url
                      : ''
                  }
                  logoLoading={loading}
                />
              </Col>
              {lg && (
                <Col>
                  <ShopSelector showModal={showModal} />
                </Col>
              )}
            </Row>
          </Col>

          {lg ? (
            <Col flex={'auto'}>
              <Row justify={'center'}>
                <HeaderMenu menuMode={'horizontal'} />
              </Row>
            </Col>
          ) : (
            <></>
          )}

          <Col>
            <Row
              justify={'space-between'}
              style={{ minWidth: lg ? 120 : 'unset' }}
              align={'middle'}
            >
              <Col>
                <Row>
                  {lg ? (
                    <>
                      <div className={styles.searchBlock}>
                        {isSearchActive ? (
                          <SearchForm
                            handleFinish={(value) => handleFinish(value)}
                            handleFormChange={(e) => handleFormChange(e)}
                            form={form}
                          />
                        ) : null}
                      </div>
                      <CustomButton
                        type={'text'}
                        onClick={handleSearch}
                        className={searchButtonStyles}
                      >
                        <Icon type={'mag-glass'} />
                      </CustomButton>
                      <Badge
                        count={count}
                        size={'small'}
                        offset={[-5, 5]}
                        color={'#2b2d45'}
                      >
                        <CustomButton
                          type={'text'}
                          onClick={onCartClick}
                          disabled={count === 0}
                        >
                          <Icon type={'cart'} />
                        </CustomButton>
                      </Badge>
                    </>
                  ) : (
                    <></>
                  )}

                  <HeaderUser />
                </Row>
              </Col>

              <CartDrawer
                isDrawerOpen={isDrawerOpen}
                toggleShowDrawer={toggleShowDrawer}
              />

              <Modal
                className={styles.shopWarningModal}
                open={open}
                centered
                width={md ? 728 : 432}
                title={
                  <>
                    <Icon type={'warning'} />
                    <Title level={4}>Warning</Title>
                  </>
                }
                onOk={handleOk}
                onCancel={handleCancel}
                okText={'Change store'}
                footer={(_, { OkBtn, CancelBtn }) => (
                  <>
                    <OkBtn />
                    <CancelBtn />
                  </>
                )}
              >
                <Paragraph>
                  You have items in your cart. If you change store, your cart
                  will be cleared.
                </Paragraph>
              </Modal>
            </Row>
          </Col>
        </Row>
      </CustomContainer>
    </header>
  );
};

export { Header };
