import { FC, useMemo } from 'react';

import { ConfigProvider, Space, Typography } from 'antd';

import { useWebsiteQuery } from '@/graphql';
import { getWebsiteFilter } from '@helpers/getWebsiteFilters';
import { customerWebsiteRoutes } from '@router/routes';
import { Logo } from '@ui/logo';
import styles from './AuthContent.module.scss';
import backgroundImage from './images/auth-background.png';

export const AuthContent: FC = () => {
  const { data, loading } = useWebsiteQuery(getWebsiteFilter());
  const website = data?.websites?.data?.[0] as WebsiteEntity;
  const websiteName = website?.attributes?.initialSettings?.heading?.title;
  const logo = website?.attributes?.initialSettings?.image?.find(
    (it) => it?.title === 'logo',
  ) as CardFragment;
  const logoUrl = logo?.media?.data?.attributes?.url;

  const headline = useMemo(
    () => ({
      id: 'create account',
      title: `Welcome to ${websiteName}`,
      subtitle:
        'Discover Exquisite Beauty. Sign up now to unlock exclusive offers and discover the allure of timeless elegance.',
    }),
    [websiteName],
  );
  return (
    <section
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
      className={styles.content}
    >
      <ConfigProvider
        theme={{
          token: {
            colorText: '#fff',
            colorTextHeading: '#fff',
          },
        }}
      >
        <div className={styles.logo}>
          <Logo
            hasLink
            url={logoUrl}
            logoLoading={loading}
            link={customerWebsiteRoutes.home}
          />
        </div>
        <Space direction={'vertical'} size={32}>
          <Space direction={'vertical'} size={16} className={styles.titleBlock}>
            <Typography.Title className={styles.title}>
              {headline.title}
            </Typography.Title>
            <Typography.Title level={3} className={styles.subtitle}>
              {headline.subtitle}
            </Typography.Title>
          </Space>
        </Space>
      </ConfigProvider>
    </section>
  );
};
