import { Col, Row } from 'antd';
import { NavLink } from 'react-router-dom';

import { useStorageLocation, useStorageType } from '@hooks/useStorage';
import { customerWebsiteRoutes } from '@router/routes';
import { useMemo } from 'react';
import styles from '../Footer.module.scss';
const { home, shop, aboutUs, blog, faq } = customerWebsiteRoutes;

const FooterPageLinks = () => {
  const locationId = useStorageLocation();
  const type = useStorageType();

  const pageLinks = useMemo(
    () => [
      {
        title: 'Home',
        link: home,
      },
      {
        title: 'Shop',
        link: `${shop}/${locationId}/${type}`,
      },
      {
        title: 'About Us',
        link: aboutUs,
      },
      {
        title: 'Blog',
        link: blog,
      },
      {
        title: 'FAQ',
        link: faq,
      },
    ],
    [locationId, type],
  );

  return (
    <Row gutter={[0, 16]}>
      {pageLinks?.map((it, index) => (
        <Col span={24} key={index}>
          <NavLink to={it?.link} className={styles.footerLink}>
            {it?.title}
          </NavLink>
        </Col>
      ))}
    </Row>
  );
};

export default FooterPageLinks;
