import { customerWebsiteRoutes } from '@router/routes';
import { Col, Row } from 'antd';
import { NavLink } from 'react-router-dom';
import styles from '../Footer.module.scss';

const { privacyPolicy, termsAndConditions } = customerWebsiteRoutes;

const FooterLegalAgreements = () => {
  return (
    <Row gutter={16}>
      <Col>
        <NavLink to={privacyPolicy} className={styles.footerLink}>
          {'Privacy Policy'}
        </NavLink>
      </Col>
      <Col>
        <NavLink to={termsAndConditions} className={styles.footerLink}>
          {'Terms & Conditions'}
        </NavLink>
      </Col>
    </Row>
  );
};

export default FooterLegalAgreements;
