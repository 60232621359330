import { Button, Col, Row, Skeleton, Typography } from 'antd';
import { FC } from 'react';
const { Text } = Typography;

import styles from '../Footer.module.scss';

type Props = {
  footerContactsData: ComponentUiWebsiteContacts | null | undefined;
  contactsLoading?: boolean;
};

const array = Array.from({ length: 3 });

const FooterContacts: FC<Props> = ({ footerContactsData, contactsLoading }) => {
  return (
    <>
      {contactsLoading ? (
        <Row gutter={[0, 16]}>
          {array.map((_, index) => (
            <Col key={index} span={24}>
              <Skeleton.Button active={true} block={true} size={'small'} />
            </Col>
          ))}
        </Row>
      ) : (
        footerContactsData && (
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Button
                type={'link'}
                href={`mailto:${footerContactsData?.email}`}
                className={styles.footerLink}
              >
                {footerContactsData?.email}
              </Button>
            </Col>
            <Col span={24}>
              <Button
                type={'link'}
                href={`tel:${footerContactsData?.phoneNumber}`}
                className={styles.footerLink}
              >
                {footerContactsData?.phoneNumber}
              </Button>
            </Col>
            <Col span={24}>
              <Text className={styles.footerText}>
                {footerContactsData?.address}
              </Text>
            </Col>
          </Row>
        )
      )}
    </>
  );
};

export default FooterContacts;
