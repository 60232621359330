import { useCallback, useMemo, useState } from 'react';

import { CustomButton } from '@ui/button/Button';
import type { MenuProps } from 'antd';
import { Divider, Dropdown } from 'antd';

import { Icon } from '@assets/icon';

import { ACCOUNT_KEYS } from '@helpers/accountKeys';
import { getAuthToken, setAuthToken } from '@helpers/auth';
import { customerWebsiteRoutes } from '@router/routes';
import { useNavigate } from 'react-router';
import styles from './HeaderUser.module.scss';

const HeaderUser = () => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { account, auth, home } = customerWebsiteRoutes;
  const { contactInfo } = ACCOUNT_KEYS;
  const token = getAuthToken();

  const onAccountClick = useCallback(() => {
    navigate(`${account}/${contactInfo}`);
  }, [account, contactInfo, navigate]);
  const onSignUpClick = useCallback(() => {
    navigate(auth.signUp);
  }, [navigate, auth]);
  const onSignOutClick = useCallback(() => {
    setAuthToken('');
    navigate(home);
  }, [navigate, home]);
  const items: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '0',
        label: (
          <div>
            <div className={styles.headerUserDropdownContent}>
              <CustomButton
                type={'text'}
                className={styles.headerUserDropdownButton}
                onClick={onAccountClick}
              >
                <Icon type={'user-secondary'} />
                My Account
              </CustomButton>
            </div>
            <Divider />
            <CustomButton
              type={'text'}
              className={styles.headerUserDropdownButton}
              onClick={onSignOutClick}
            >
              <Icon type={'sign-out'} />
              Sign Out
            </CustomButton>
          </div>
        ),
      },
    ];
  }, [onAccountClick, onSignOutClick]);
  const signUpItems: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '0',
        label: (
          <div>
            <CustomButton
              type={'text'}
              className={styles.headerUserDropdownButton}
              onClick={onSignUpClick}
            >
              <Icon type={'sign-out'} />
              Sign Up
            </CustomButton>
          </div>
        ),
      },
    ];
  }, [onSignUpClick]);

  return (
    <Dropdown
      menu={token ? { items } : { items: signUpItems }}
      onOpenChange={(visible) => setDropdownVisible(visible)}
      overlayClassName={styles.menuDropdownWrapper}
      placement={'topRight'}
    >
      <a
        onClick={(e) => e.preventDefault()}
        className={styles.headerUserIcon}
        style={{
          backgroundColor: dropdownVisible ? '#F0F3FB' : '',
        }}
      >
        <Icon type={'user'} />
      </a>
    </Dropdown>
  );
};

export default HeaderUser;
